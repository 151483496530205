// Generated by CoffeeScript 1.12.7

/*
  Get column width LESS

  @param columnWidth [String] one of "regular", "medium", or "large"
  @param blockTypes [Array[String]] the types of blocks that this style can be used for
 */

(function () {
  var addPercent,
    columnWidthSplashMap,
    columnWidthTextMap,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  columnWidthTextMap = {
    regular: 66.66667,
    medium: 75,
    large: 85,
  };

  columnWidthSplashMap = {
    regular: 55,
    medium: 70,
    large: 90,
  };

  addPercent = function (num) {
    return num + "%";
  };

  module.exports = function (columnWidth, blockTypes) {
    var lessStr, splashCss, videoCss;
    lessStr = "";
    if (indexOf.call(blockTypes, "Splash") >= 0) {
      splashCss =
        "max-width: " +
        addPercent(columnWidthSplashMap[columnWidth]) +
        ";\nmin-width: " +
        addPercent(columnWidthSplashMap[columnWidth]) +
        ";";
      lessStr +=
        "&.splash-module .fill-block .content {\n  @media all and (min-width: 501px) {\n    " +
        splashCss +
        "\n    &.noBackground {\n      " +
        splashCss +
        "\n    }\n    &.withBackground {\n      " +
        splashCss +
        "\n    }\n  }\n}";
    }
    if (indexOf.call(blockTypes, "Text") >= 0) {
      lessStr +=
        "@media only screen and (min-width: 768px) {\n  .columns {\n    width: " +
        addPercent(columnWidthTextMap[columnWidth]) +
        ";\n  }\n}";
    }
    if (indexOf.call(blockTypes, "Video") >= 0) {
      if (columnWidth === "regular") {
        videoCss = "max-width: 600px";
      } else {
        videoCss = "max-width: " + addPercent(columnWidthTextMap[columnWidth]);
      }
      lessStr += "@media only screen and (min-width: 800px) {\n  .video-header {\n    " + videoCss + ";\n  }\n}";
    }
    return lessStr;
  };
}.call(this));
