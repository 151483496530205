"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedBlockCollection = exports.ProjectCollection = void 0;
var ProjectCollection;
(function (ProjectCollection) {
    ProjectCollection["All"] = "all-projects";
    ProjectCollection["Deleted"] = "deleted";
    ProjectCollection["Archived"] = "archived";
})(ProjectCollection || (exports.ProjectCollection = ProjectCollection = {}));
var SavedBlockCollection;
(function (SavedBlockCollection) {
    SavedBlockCollection["All"] = "all-blocks";
})(SavedBlockCollection || (exports.SavedBlockCollection = SavedBlockCollection = {}));
