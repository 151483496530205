// Generated by CoffeeScript 1.12.7

/*
  StyleService used in both Client and Server to manage the style object (v2)
 */

(function () {
  var StyleService,
    applyBlockSpecificLESS,
    defaultStyles,
    getColor,
    getColumnWidthLESS,
    getMargin,
    getSize,
    lessCompiler,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  getColor = require("./GetColor");

  getSize = require("./GetSize");

  getMargin = require("./GetMargin");

  getColumnWidthLESS = require("./GetColumnWidthLess");

  applyBlockSpecificLESS = require("./ApplyBlockSpecificLess");

  lessCompiler = require("less");

  defaultStyles = require("./DefaultStyles")["default"];

  /*
    Definitions of terminologies used related to styles:
    - style [Object] the style object that's stored on the account
    - compiledCSS [String] the compiled CSS as a string
    - less (or lessStr where "less" is a confusing word) [String] the uncompiled LESS as a string
     * @NOTE(mike, 2017-10-30) I tried to implement this using a lessObject, a data structure
     * representing the less code in a fancy datastructure but juggling arrays and objects posed
     * as more of a complication than any simplifications it may provide
   */

  module.exports = StyleService = (function () {
    function StyleService() {
      /*
        Returns an array of default style objects
        @param blockType [String] the type of block we're filtering by, e.g. "Text", "Splash"
        @param tag [String] (Optional) the type of tag we're filtering by, e,g, "Titles", "Paragraphs"
       */
      var RATIO_RANGE,
        SCALE_RANGE,
        SPACING_RANGE,
        getBorderStyle,
        getColorVariations,
        getCommonLess,
        getLess,
        getPercentFromValueAndRange,
        getValueFromPercentAndRange,
        getWhiteVariations;
      this.getDefaultStyles = function (blockType, tag) {
        return defaultStyles.filter(function (style) {
          var isRightBlockType;
          isRightBlockType = indexOf.call(style.blockTypes, blockType) >= 0;
          if (tag != null) {
            return isRightBlockType && style.tag === tag;
          } else {
            return isRightBlockType;
          }
        });
      };

      /*
        Returns a string of the CSS code of a given style
        @param style [Object] the style object
        @param accountColors [Object] the brand and complementary colors on the account
        @param prefix [String] the name of the class this style is to be applied to
       */
      this.getCompiledCss = (function (_this) {
        return function (style, accountColors, prefix) {
          var colorVariables;
          if (prefix == null) {
            prefix = ".project-block";
          }
          colorVariables = _this.getColorVariables(accountColors);
          return lessCompiler
            .render(colorVariables + " " + prefix + "." + style.className + "{" + getLess(style, accountColors) + "}")
            .then(function (arg) {
              var css;
              css = arg.css;
              return css + (style.css || "");
            });
        };
      })(this);

      /*
        Returns a string of the CSS code of all the styles on an account
        @param account [Object]
       */
      this.getAccountWideCompiledCss = (function (_this) {
        return function (account) {
          var allStyles, lessStr;
          allStyles = _.concat(defaultStyles, account.styles);
          lessStr = _.reduce(
            allStyles,
            function (lessStr, style) {
              if (style.version === 1) {
                return (
                  lessStr +
                  (".project-block." + style.className + "{" + getLess(style, account.settings.brand.colors) + "}")
                );
              } else {
                return lessStr;
              }
            },
            "" + _this.getColorVariables(account.settings.brand.colors),
          );
          return lessCompiler.render(lessStr).then(function (arg) {
            var css;
            css = arg.css;
            return _.reduce(
              account.styles,
              function (cssStr, style) {
                return cssStr + (style.css || "");
              },
              css,
            );
          });
        };
      })(this);

      /*
        Returns a string of the LESS code of the color Variables
        @param accountColors [Object] the brand and complementary colors on the account
       */
      this.getColorVariables = function (accountColors) {
        return "@brand: " + accountColors.brand + ";\n@complementary: " + accountColors.complementary + ";";
      };
      getLess = function (style, accountColors) {
        var columnWidthLESS, commonLESS;
        commonLESS = getCommonLess(style, accountColors);
        columnWidthLESS = getColumnWidthLESS(style.columnWidth, style.blockTypes);
        return applyBlockSpecificLESS(style, accountColors, commonLESS) + columnWidthLESS;
      };
      getCommonLess = function (style, accountColors) {
        var commonLess, fontStyles, h1Sizes, h2Sizes, inheritColor, textBackground;
        fontStyles = style.fontStyles;
        h1Sizes = {
          desktop: getSize(fontStyles, "h1", "desktop"),
          mobile: getSize(fontStyles, "h1", "mobile"),
          print: getSize(fontStyles, "h1", "print"),
        };
        h2Sizes = {
          desktop: getSize(fontStyles, "h2", "desktop"),
          mobile: getSize(fontStyles, "h2", "mobile"),
          print: getSize(fontStyles, "h2", "print"),
        };

        /*
          @NOTE (mike, 2017-11-09) The max-width on the media query comes from @portraitBreakPoint
        
          @NOTE (som, 2018-06-20) Block quotes (or 'pull quotes') now take on the same colour as
          paragraphs, as it was decided that this is something most users would want. Block quotes
          do NOT take on any other attributes of paragraphs, as this would be a much bigger change
          that users might not want.
        
          @NOTE (som, 2018-11-16): Notes galore! We use this `.print & {}` pattern to apply custom
          styles to the print mode. Because custom styles are applied on the `project-block` level,
          but the `print` class is on the `<body>`, this pattern allows the CSS to 'reach up' past
          the `project-block`
         */
        commonLess =
          "h1 {\n  font-size: " +
          h1Sizes.desktop +
          ";\n  min-height: " +
          h1Sizes.desktop +
          ";\n  margin-bottom: " +
          getMargin(fontStyles, "h1") +
          ";\n  color: " +
          getColor(accountColors, style.colors.h1.name, style.colors.h1.modifier) +
          ";\n  text-align: " +
          style.alignment.h1 +
          ";\n  @media all and (max-width: 500px){\n    font-size: " +
          h1Sizes.mobile +
          ";\n    min-height: " +
          h1Sizes.mobile +
          ";\n  }\n  #print &, .print-legacy & {\n    font-size: " +
          h1Sizes.print +
          ";\n  }\n}\nh2 {\n  font-size: " +
          h2Sizes.desktop +
          ";\n  min-height: " +
          h2Sizes.desktop +
          ";\n  margin-bottom: " +
          getMargin(fontStyles, "h2") +
          ";\n  color: " +
          getColor(accountColors, style.colors.h2.name, style.colors.h2.modifier) +
          ";\n  text-align: " +
          style.alignment.h2 +
          ";\n  @media all and (max-width: 500px){\n    font-size: " +
          h2Sizes.mobile +
          ";\n    min-height: " +
          h2Sizes.mobile +
          ";\n  }\n  #print &, .print-legacy & {\n    font-size: " +
          h2Sizes.print +
          ";\n  }\n}\np {\n  font-size: " +
          getSize(fontStyles, "p") +
          ";\n  margin-bottom: " +
          getMargin(fontStyles, "p") +
          ";\n  color: " +
          getColor(accountColors, style.colors.p.name, style.colors.p.modifier) +
          ";\n  text-align: " +
          style.alignment.p +
          ";\n  @media all and (max-width: 500px){\n    font-size: " +
          getSize(fontStyles, "p", "mobile") +
          ";\n  }\n  #print &, .print-legacy & {\n    font-size: " +
          getSize(fontStyles, "p", "print") +
          ";\n  }\n}\nhr {\n  border-color: fade(" +
          getColor(accountColors, style.colors.p.name, style.colors.p.modifier) +
          ", 25%);\n  border-style: solid;\n}\nblockquote {\n  color: " +
          getColor(accountColors, style.colors.p.name, style.colors.p.modifier) +
          ";\n}\nli {\n  font-size: " +
          getSize(fontStyles, "li") +
          ";\n  color: " +
          getColor(accountColors, style.colors.p.name, style.colors.p.modifier) +
          ";\n  @media all and (max-width: 500px){\n    font-size: " +
          getSize(fontStyles, "li", "mobile") +
          ";\n  }\n  #print &, .print-legacy & {\n    font-size: " +
          getSize(fontStyles, "li", "print") +
          ";\n  }\n}\ninsertable:not(.widget) {\n  margin: " +
          getMargin(fontStyles, "insertable") +
          ";\n}";
        textBackground = style.backgroundCard || style.background;
        if (textBackground === "brand" || textBackground === "complementary") {
          inheritColor = "color: inherit;\nborder-bottom-color: currentColor;";
          commonLess += "a {\n  " + inheritColor + "\n  &:hover {\n    " + inheritColor + "\n  }\n}";
        } else if (style.backgroundCard === "white") {
          commonLess +=
            "a {\n  color: " + accountColors.brand + ";\n  border-color: fade(" + accountColors.brand + ", 40%);\n}";
        }
        return commonLess;
      };

      /*
        Returns the color variations in an object of the color name to an array containing all
        permutations of possible colors based on that initial color
        @param accountColors [Object] the brand and complementary colors on the account
       */
      this.getColorVariationsByName = function (accountColors) {
        return {
          white: getWhiteVariations(accountColors),
          brand: getColorVariations("brand", accountColors),
          complementary: getColorVariations("complementary", accountColors),
          slate: getColorVariations("slate", accountColors),
        };
      };
      getWhiteVariations = function (accountColors) {
        return [
          {
            name: "white",
            hex: getColor(accountColors, "white", null),
            modifier: null,
          },
          {
            name: "white",
            hex: getColor(accountColors, "white", "darken"),
            modifier: "translucent",
          },
        ];
      };
      getColorVariations = function (name, accountColors) {
        return [
          {
            name: name,
            hex: getColor(accountColors, name, null),
            modifier: null,
          },
          {
            name: name,
            hex: getColor(accountColors, name, "darken"),
            modifier: "darken",
          },
        ];
      };

      /*
        Returns the background colors as an array with some other information like the borderStyle
        @param accountColors [Object] the brand and complementary colors on the account
        @param forSplashBlocks [boolean] if the background is for splash blocks
       */
      this.getBackgrounds = function (accountColors, forSplashBlocks) {
        var options;
        if (forSplashBlocks == null) {
          forSplashBlocks = false;
        }
        options = ["brand", "complementary", "white", "slate"];
        if (forSplashBlocks) {
          options.push("none");
        } else {
          options.push("silver");
        }
        return _.map(options, function (name) {
          return {
            name: name,
            hex: getColor(accountColors, name, null),
            borderStyle: getBorderStyle(name),
          };
        });
      };
      getBorderStyle = function (name) {
        if (name === "none") {
          return "dashed";
        } else {
          return "solid";
        }
      };
      getValueFromPercentAndRange = function (min, max) {
        return function (percent) {
          return min + ((max - min) * percent) / 100;
        };
      };
      SCALE_RANGE = [16, 26];
      RATIO_RANGE = [1.2, 1.618];
      SPACING_RANGE = [0, 1];
      getPercentFromValueAndRange = function (min, max) {
        return function (val) {
          return Math.round((100 * (val - min)) / (max - min));
        };
      };
      this.getScaleFromPercent = getValueFromPercentAndRange.apply(null, SCALE_RANGE);
      this.getRatioFromPercent = getValueFromPercentAndRange.apply(null, RATIO_RANGE);
      this.getSpacingFromPercent = getValueFromPercentAndRange.apply(null, SPACING_RANGE);
      this.getPercentFromScale = getPercentFromValueAndRange.apply(null, SCALE_RANGE);
      this.getPercentFromRatio = getPercentFromValueAndRange.apply(null, RATIO_RANGE);
      this.getPercentFromSpacing = getPercentFromValueAndRange.apply(null, SPACING_RANGE);
    }

    return StyleService;
  })();
}.call(this));
