// Generated by CoffeeScript 1.12.7

/*
  This module provides a the models for each quote item type.
 */

(function () {
  module.exports = function (defaultRate) {
    return {
      rate: {
        type: "rate",
        name: "",
        cost: 0,
        units: 0,
        rate: defaultRate,
        discount: 0,
        interactive: {
          type: "none",
          settings: {},
        },
      },
      fixedCost: {
        type: "fixedCost",
        name: "",
        cost: 0,
        units: 1,
        discount: 0,
        rate: {
          unit: "Unit",
          rate: 0,
        },
        interactive: {
          type: "none",
          settings: {},
        },
      },
      text: {
        type: "text",
        name: "",
        cost: 0,
        units: 0,
        discount: 0,
        interactive: {
          type: "none",
          settings: {},
        },
      },
    };
  };
}.call(this));
