"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToEmbedUrl = void 0;
var url_1 = require("url");
var convertToEmbedUrl = function (displayUrl) {
    var url = displayUrl;
    if (url.includes("<iframe")) {
        url = getSrcFromIframeString(displayUrl);
    }
    var urlObj = (0, url_1.parse)(url);
    if (urlObj.hostname &&
        urlObj.hostname.includes("google.") &&
        urlObj.path &&
        urlObj.path.includes("maps") &&
        urlObj.path.includes("embed")) {
        return url;
    }
    throw new Error("Invalid Google maps URL provided");
};
exports.convertToEmbedUrl = convertToEmbedUrl;
var getSrcFromIframeString = function (iframeString) {
    var firstSplit = iframeString.split(/src=["']/)[1];
    if (firstSplit == undefined) {
        throw new Error("Did not get iframe string in expected format");
    }
    var secondSplit = firstSplit.split(/["']/)[0];
    return secondSplit;
};
