"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSplashStyle = exports.getQuoteStyle = exports.getAcceptStyle = exports.getTextStyle = void 0;
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var sampleColors = ["#716BF1", "#33AFFF"];
var getBase = function (colors) {
    if (colors === void 0) { colors = sampleColors; }
    return {
        blockType: "Text",
        css: "",
        h1: {
            color: {
                themeIndex: 0,
                abs: colors[0],
            },
            size: {
                abs: 65,
                themeRef: "medium",
            },
            alignment: "left",
        },
        h2: {
            color: {
                themeIndex: 1,
                abs: colors[1],
            },
            size: {
                abs: 40,
                themeRef: "medium",
            },
            alignment: "left",
        },
        p: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            size: {
                abs: 20,
                themeRef: "medium",
            },
            alignment: "left",
        },
        blockQuote: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            size: {
                abs: 27,
                themeRef: "medium",
            },
            barColor: {
                themeIndex: 0,
                abs: colors[0],
            },
            displayQuoteIcon: false,
        },
        background: {
            themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
            abs: DefaultColors_1.DEFAULT_WHITE.value,
        },
        textSize: {
            ref: "small",
            abs: 14,
        },
        list: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            marker: commonTypes_1.ListMarkerType.Circle,
            markerColor: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
        },
        link: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            underline: {
                on: true,
                color: {
                    themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                    abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
                },
            },
        },
        blockWidth: "medium",
        blockSpacing: "medium",
        tableBackground: {
            on: false,
            color: {
                themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                abs: DefaultColors_1.DEFAULT_WHITE.value,
            },
        },
        tableHeader: {
            on: false,
            color: {
                themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                abs: DefaultColors_1.DEFAULT_WHITE.value,
            },
        },
        tableBorder: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_GREY.themeIndex,
                abs: DefaultColors_1.DEFAULT_GREY.value,
                opacity: 1,
            },
            borderType: "all",
        },
        table: {
            background: {
                on: false,
                color: {
                    themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                    abs: DefaultColors_1.DEFAULT_WHITE.value,
                },
            },
            header: {
                on: false,
                color: {
                    themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                    abs: DefaultColors_1.DEFAULT_WHITE.value,
                },
            },
            border: {
                color: {
                    themeIndex: DefaultColors_1.DEFAULT_GREY.themeIndex,
                    abs: DefaultColors_1.DEFAULT_GREY.value,
                    opacity: 1,
                },
                borderType: "all",
            },
        },
        animation: {
            animationOption: commonTypes_1.AnimationOption.None,
            animationType: commonTypes_1.AnimationType.Fade,
            direction: commonTypes_1.AnimationDirection.Down,
            speed: commonTypes_1.AnimationSpeed.Medium,
        },
    };
};
exports.default = getBase();
var getTextStyle = function (colors) {
    if (colors === void 0) { colors = sampleColors; }
    return getBase(colors);
};
exports.getTextStyle = getTextStyle;
var getAcceptStyle = function (colors) {
    if (colors === void 0) { colors = sampleColors; }
    return __assign(__assign({}, getBase(colors)), { blockType: "Accept", acceptButton: {
            color: {
                themeIndex: 0,
                abs: colors[0],
            },
            alignment: "center",
        } });
};
exports.getAcceptStyle = getAcceptStyle;
var getQuoteStyle = function (colors) {
    if (colors === void 0) { colors = sampleColors; }
    return __assign(__assign({}, getBase(colors)), { blockType: "QuoteV2", uiHighlightColor: {
            themeIndex: 0,
            abs: colors[0],
        }, headerSection: {
            on: true,
            color: {
                themeIndex: 0,
                abs: colors[0],
            },
        }, quoteBackgroundOn: true });
};
exports.getQuoteStyle = getQuoteStyle;
var getSplashStyle = function (colors) {
    if (colors === void 0) { colors = sampleColors; }
    return __assign(__assign({}, getBase(colors)), { blockType: "Splash", tintBlendMode: "blend", background: {
            themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
            abs: DefaultColors_1.DEFAULT_WHITE.value,
            opacity: 0.3,
        }, backgroundCard: {
            on: false,
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
                opacity: 1,
            },
        } });
};
exports.getSplashStyle = getSplashStyle;
