// Generated by CoffeeScript 1.12.7
(function () {
  var _, getHex, getRgb, getRgba, isValidColor, isValidHex, ref, replaceOpacity, shouldUseDarkText;

  _ = require("lodash");

  (ref = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils")),
    (getRgba = ref.getRgba),
    (getRgb = ref.getRgb),
    (getHex = ref.getHex),
    (isValidColor = ref.isValidColor),
    (isValidHex = ref.isValidHex),
    (shouldUseDarkText = ref.shouldUseDarkText),
    (replaceOpacity = ref.replaceOpacity);

  module.exports = {
    isValidColor: isValidColor,
    isValidHex: isValidHex,
    shouldUseDarkText: shouldUseDarkText,
    mixColors: function (hex1, hex2, percent) {
      var b, g, getWeightedAverage, r, rgb1, rgb2;
      getWeightedAverage = function (a, b, percent) {
        return Math.round(((a - b) * percent) / 100 + b);
      };
      rgb1 = getRgb(hex1).split(/[\(\)\,\s]+/);
      rgb2 = getRgb(hex2).split(/[\(\)\,\s]+/);
      r = getWeightedAverage(parseInt(rgb1[1]), parseInt(rgb2[1]), percent);
      g = getWeightedAverage(parseInt(rgb1[2]), parseInt(rgb2[2]), percent);
      b = getWeightedAverage(parseInt(rgb1[3]), parseInt(rgb2[3]), percent);
      return getHex("rgb(" + r + ", " + g + ", " + b + ")");
    },
    fade: function (hex, percent) {
      var color;
      color = getRgba(hex);
      return replaceOpacity(color, percent / 100);
    },
    areColorsEqual: function (color1, color2) {
      if (!isValidColor(color1) || !isValidColor(color2)) {
        return false;
      } else {
        return getRgba(color1) === getRgba(color2);
      }
    },
  };
}.call(this));
