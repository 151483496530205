// Generated by CoffeeScript 1.12.7
(function () {
  var navigationModel;

  navigationModel = require("./Navigation");

  module.exports = {
    name: "",
    type: "Video",
    cssBlockName: "video-module",
    url: "",
    embedUrl: "",
    settings: {
      navigation: navigationModel,
      size: "standard",
      loop: false,
      autoplay: false,
    },
    contentAreas: {
      header: {
        content: '<h2 placeholder="true"><br></h2>\n<p placeholder="true"><br></p>',
        widgets: {},
      },
    },
  };
}.call(this));
