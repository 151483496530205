// Generated by CoffeeScript 1.12.7
(function () {
  var formatterCurrencies, getQwilrCurrencies;

  formatterCurrencies = require("currency-formatter/currencies.json");

  getQwilrCurrencies = require("@CommonFrontendBackend/Quote/Models/Currencies");

  module.exports = function (imageLocation) {
    var currency, i, len, qwilrCurrencies;
    qwilrCurrencies = getQwilrCurrencies(imageLocation);
    for (i = 0, len = qwilrCurrencies.length; i < len; i++) {
      currency = qwilrCurrencies[i];
      if (formatterCurrencies[currency.code] != null) {
        currency.symbol = formatterCurrencies[currency.code].symbol;
      } else {
        throw new Error(
          "Currency code mismatch between Qwilr and currency-formatter. Could not find code " + currency.code,
        );
      }
    }
    return qwilrCurrencies;
  };
}.call(this));
