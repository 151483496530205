"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var navigationModel = require("./Navigation");
var base = {
    name: "",
    type: "Agreement",
    cssBlockName: "agreement-block",
    settings: {
        navigation: navigationModel,
        style: "",
        showDownloadButton: true,
        showNavigation: true,
    },
    contentAreas: {
        mainContent: {
            content: '<h1 placeholder="true">\n  <br>\n</h1>\n<p placeholder="true">\n  <br>\n</p>',
            widgets: {},
        },
    },
};
exports.default = base;
