"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrigin = void 0;
var hasLocation = function () {
    return !!window.location;
};
var getOrigin = function () {
    if (hasLocation()) {
        return new URL(window.location.href).origin;
    }
    else {
        return "";
    }
};
exports.getOrigin = getOrigin;
