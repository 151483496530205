"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.templateMetadata = exports.companyTypes = exports.useCases = exports.categoryLabels = exports.templateGalleryCategories = exports.categories = void 0;
exports.categories = [
    "Agenda",
    "Announcement",
    "Brochure",
    "Case study",
    "Contract",
    "Customer success",
    "Guides",
    "Hiring",
    "Invitation",
    "Invoice",
    "Job description",
    "Meeting schedule",
    "Newsletter",
    "Onboarding",
    "One pager",
    "Online brochure",
    "Planner",
    "Presentation",
    "Press release",
    "Program",
    "Proposal",
    "Quote",
    "Report",
    "Reporting",
    "Sales process",
    "Other",
];
exports.templateGalleryCategories = [
    "Proposal",
    "Sales process",
    "Onboarding",
    "Customer success",
    "Case study",
    "Quote",
    "Reporting",
    "Online brochure",
    "Guides",
];
exports.categoryLabels = __spreadArray(["All categories"], __read(exports.templateGalleryCategories), false);
exports.useCases = ["Pitch", "Update", "Promote"];
exports.companyTypes = ["Agency", "Consultancy", "Events", "Nonprofit", "Software", "Personal", "Other"];
exports.templateMetadata = {
    categories: exports.categories,
    useCases: exports.useCases,
    companyTypes: exports.companyTypes,
};
