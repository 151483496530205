"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeSinceInHours = exports.getTimeSinceInSeconds = void 0;
var getTimeSinceInSeconds = function (timeSinceTime) {
    var current = new Date();
    return (current.getTime() - timeSinceTime.getTime()) / 1000;
};
exports.getTimeSinceInSeconds = getTimeSinceInSeconds;
var getTimeSinceInHours = function (timeSinceTime) {
    return (0, exports.getTimeSinceInSeconds)(timeSinceTime) / 3600;
};
exports.getTimeSinceInHours = getTimeSinceInHours;
