// Generated by CoffeeScript 1.12.7
(function () {
  var getBackgroundCardLESS,
    getColor,
    getImageLESS,
    getTintLESS,
    isForSplashBlock,
    isOnlyForSplashBlock,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  getColor = require("./GetColor");

  isForSplashBlock = function (style) {
    return indexOf.call(style.blockTypes, "Splash") >= 0;
  };

  isOnlyForSplashBlock = function (style) {
    return style.blockTypes.length === 1 && style.blockTypes[0] === "Splash";
  };

  getImageLESS = function (background, accountColors) {
    if (background === "white") {
      return "background-blend-mode: normal; opacity: 0.5; background-color: #fff;";
    } else if (background === "none") {
      return "background-blend-mode: normal; opacity: initial;";
    } else {
      return (
        "background-blend-mode: multiply; opacity: initial; background-color: " +
        getColor(accountColors, background, null)
      );
    }
  };

  getTintLESS = function (tint, accountColors) {
    /*
      @NOTE(mike, 2018-03-21) This is a new implementation of tints. Instead of it being a block setting, it is now a custom style which means the tint always needs to be there but just undisplayed if the tint isn't used.
     */
    if (tint === "none") {
      return ".tint-v2 {\n  display: none;\n  background-color: initial;\n}";
    } else if (tint != null) {
      return (
        ".tint-v2 {\n  display: block;\n  opacity:0.3;\n  position:absolute;\n  top:0;\n  left:0;\n  z-index: 2;\n  height:100%;\n  width:100%;\n  background-color: " +
        getColor(accountColors, tint, null) +
        ";\n}"
      );
    } else {
      return "";
    }
  };

  getBackgroundCardLESS = function (backgroundCard, accountColors) {
    if (backgroundCard === "none") {
      return "background: none;";
    } else {
      return "background-color: " + getColor(accountColors, backgroundCard, null) + ";";
    }
  };

  module.exports = function (style, accountColors, commonLESS) {
    var backgroundLESS, less;
    less = "";
    if (!isOnlyForSplashBlock(style)) {
      less += "background: " + getColor(accountColors, style.background, null) + ";\n" + commonLESS;
    }
    if (isForSplashBlock(style)) {
      backgroundLESS = getBackgroundCardLESS(style.backgroundCard, accountColors);
      less +=
        ".image{\n  " +
        getImageLESS(style.background, accountColors) +
        "\n}\ntext-shadow: none;\n&.splash-module .fill-block{\n  .content{\n    &.noBackground{\n      " +
        backgroundLESS +
        "\n      " +
        commonLESS +
        "\n    }\n    &.withBackground{\n      " +
        backgroundLESS +
        "\n      " +
        commonLESS +
        "\n    }\n  }\n  " +
        getTintLESS(style.tint, accountColors) +
        "\n}";
    }
    return less;
  };
}.call(this));
