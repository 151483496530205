"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePageFlowSource = exports.ASSETS_DIR = void 0;
var ASSETS_DIR = "/Assets/";
exports.ASSETS_DIR = ASSETS_DIR;
var CreatePageFlowSource;
(function (CreatePageFlowSource) {
    CreatePageFlowSource["Qwilr"] = "qwilr";
    CreatePageFlowSource["Crm"] = "crm";
})(CreatePageFlowSource || (exports.CreatePageFlowSource = CreatePageFlowSource = {}));
