"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageLibrarySystemFolder = exports.CollectionType = void 0;
var CollectionType;
(function (CollectionType) {
    CollectionType["Page"] = "Page";
    CollectionType["Block"] = "Block";
    CollectionType["UploadedImage"] = "UploadedImage";
})(CollectionType || (exports.CollectionType = CollectionType = {}));
var ImageLibrarySystemFolder;
(function (ImageLibrarySystemFolder) {
    ImageLibrarySystemFolder["Unsplash"] = "unsplash";
    ImageLibrarySystemFolder["RecentlyUsed"] = "recentlyUsed";
    ImageLibrarySystemFolder["Uploads"] = "uploads";
})(ImageLibrarySystemFolder || (exports.ImageLibrarySystemFolder = ImageLibrarySystemFolder = {}));
