"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderedAccessLevels = exports.UserInvitePermission = exports.Role = exports.getActionFromString = exports.Action = void 0;
var Action;
(function (Action) {
    Action["Edit"] = "edit";
    Action["View"] = "view";
    Action["All"] = "all";
    Action["None"] = "none";
})(Action || (exports.Action = Action = {}));
var getActionFromString = function (action) {
    switch (action) {
        case "edit":
            return Action.Edit;
        case "view":
            return Action.View;
        case "all":
            return Action.All;
        case "none":
            return Action.None;
        default:
            throw new Error("Unknown action string: ".concat(action));
    }
};
exports.getActionFromString = getActionFromString;
var Role;
(function (Role) {
    Role["Admin"] = "admin";
    Role["Creator"] = "creator";
})(Role || (exports.Role = Role = {}));
var UserInvitePermission;
(function (UserInvitePermission) {
    UserInvitePermission["Admin"] = "admin";
    UserInvitePermission["Creator"] = "creator";
    UserInvitePermission["Domain"] = "domain";
})(UserInvitePermission || (exports.UserInvitePermission = UserInvitePermission = {}));
exports.OrderedAccessLevels = [Action.All, Action.Edit, Action.View, Action.None];
