// Generated by CoffeeScript 1.12.7
(function () {
  var navigationModel;

  navigationModel = require("./Navigation");

  module.exports = {
    name: "",
    type: "Text",
    cssBlockName: "text-block",
    settings: {
      navigation: navigationModel,
      foldable: {
        foldable: false,
        isFolded: false,
        label: "Show / Hide",
      },
      style: "",
    },
    contentAreas: {
      mainContent: {
        content: '<h1 placeholder="true">\n  <br>\n</h1>\n<p placeholder="true">\n  <br>\n</p>',
        widgets: {},
      },
    },
  };
}.call(this));
