// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (_) {
    var convertFromV1, convertLineItem, convertOneSection, convertSections, convertSettings, getVariableFromRate;
    convertFromV1 = function (account, oldQuote) {
      var converted;
      if (oldQuote.type !== "Quote") {
        throw new Error("Given an invalid block type");
      }
      converted = {
        name: oldQuote.name,
        type: "QuoteV2",
        cssBlockName: "quote-v2-module",
        contentAreas: oldQuote.contentAreas,
        selectionType: "combined",
        persistVersion: 0,
      };
      if (oldQuote._id != null) {
        converted._id = oldQuote._id;
      }
      if (oldQuote.projectId != null) {
        converted.projectId = oldQuote.projectId;
      }
      if (oldQuote.createdAt != null) {
        converted.createdAt = oldQuote.createdAt;
      }
      if (oldQuote.createdBy != null) {
        converted.createdBy = oldQuote.createdBy;
      }
      if (oldQuote.isAccepted != null) {
        converted.isAccepted = oldQuote.isAccepted;
      }
      if (oldQuote.id != null) {
        converted.id = oldQuote.id;
      }
      converted = _.extend(converted, {
        settings: convertSettings(oldQuote),
      });
      converted.sections = convertSections(oldQuote, account);
      if (oldQuote.discount.amount > 0) {
        converted.discount = {
          enabled: true,
          units: oldQuote.discount.amount,
          type: oldQuote.discount.type,
        };
      } else {
        converted.discount = {
          enabled: false,
          type: "percent",
          units: 0,
        };
      }
      return converted;
    };
    convertSettings = function (oldQuote) {
      var enabledTaxes, i, len, oldSettings, ref, showQuoteTotals, tax;
      oldSettings = oldQuote.settings;
      if (oldSettings == null) {
        throw new Error("Given quote with no settings object");
      }
      if (oldSettings.displayConfig == null) {
        throw new Error("No display configuration provided for quote");
      }
      enabledTaxes = {};
      if (oldQuote.taxes != null) {
        ref = oldQuote.taxes;
        for (i = 0, len = ref.length; i < len; i++) {
          tax = ref[i];
          enabledTaxes[tax.id] = true;
        }
      }
      if (oldSettings.displayConfig.total != null) {
        showQuoteTotals = oldSettings.displayConfig.total;
      } else {
        showQuoteTotals = true;
      }
      return {
        foldable: {},
        style: "qwilr-standard",
        enabledTaxes: enabledTaxes,
        navigation: oldSettings.navigation,
        currency: oldSettings.currency.code,
        view: {
          showDecimalPlaces: true,
          showExTax: oldSettings.displayConfig.subTotal || false,
          showTaxTotals: oldSettings.displayConfig.taxes || false,
          showQuoteTotals: showQuoteTotals,
        },
      };
    };
    convertSections = function (oldQuote, account) {
      var convertedSections, i, len, oldDisplay, oldSection, ref, sectionViewSettings;
      if (oldQuote.sections == null) {
        throw new Error("Was given a quote with no sections attribute");
      }
      oldDisplay = oldQuote.settings.displayConfig;
      sectionViewSettings = {
        itemsViewable: !oldDisplay.hideItems,
        showRateAndQuantity: oldDisplay.unitPrice || oldDisplay.units,
        showCost: true,
        showSubtotal: oldDisplay.sectionSubTotals,
      };
      convertedSections = [];
      ref = oldQuote.sections;
      for (i = 0, len = ref.length; i < len; i++) {
        oldSection = ref[i];
        convertedSections.push(convertOneSection(oldSection, sectionViewSettings, account));
      }
      return convertedSections;
    };
    convertOneSection = function (oldSection, viewSettings, account) {
      var converted, i, items, len, oldItem, ref;
      items = [];
      ref = oldSection.items;
      for (i = 0, len = ref.length; i < len; i++) {
        oldItem = ref[i];
        items.push(convertLineItem(oldItem, account));
      }
      converted = {
        description: {
          content: "<p>" + oldSection.name + "</p>",
          widgets: {},
        },
        items: items,
        viewSettings: viewSettings,
        discount: {
          enabled: false,
          type: "fixed",
          units: 0,
        },
      };
      return converted;
    };
    convertLineItem = function (item, account) {
      var base, converted, range, ref, ref1, type, variable;
      if (item.type === "rate") {
        type = "variable";
      } else {
        type = item.type;
      }
      converted = {
        type: type,
        description: {
          content: "<p>" + item.name + "</p>",
          widgets: {},
        },
        quantity: item.units || 0,
      };
      if (type === "variable") {
        variable = getVariableFromRate(item.rate, account);
        if (variable != null) {
          converted.rate = {
            variableId: variable.id,
          };
        } else {
          converted.type = "fixedCost";
          converted.rate = {
            unit: item.rate.unit,
            rate: item.rate.rate,
          };
        }
      } else {
        converted.rate = item.rate;
      }
      converted.discount = {
        type: "fixed",
        units: item.discount || 0,
        enabled: item.discount > 0,
      };
      converted.interactive = {
        isOptional: false,
        isOptionalSelected: false,
        isQuantityOptional: false,
        quantityRange: {
          min: 0,
          max: 0,
        },
      };
      if (item.interactive != null) {
        if ((base = item.interactive).settings == null) {
          base.settings = {};
        }
      }
      if (((ref = item.interactive) != null ? ref.type : void 0) === "optional") {
        converted.interactive.isOptional = true;
        converted.interactive.isOptionalSelected = item.interactive.settings.enabled;
      } else if (((ref1 = item.interactive) != null ? ref1.type : void 0) === "quantity") {
        /*
          Since the legacy range settings don't enforce a min and max, we need to make some
          assumptions about what the min and max should be if either are missing
         */
        range = item.interactive.settings;
        if (range.min == null) {
          range.min = 0;
        }
        converted.quantity = Math.max(converted.quantity, range.min);
        if (range.max == null) {
          if (converted.quantity > 0) {
            range.max = 100 * converted.quantity;
          } else {
            range.max = 100;
          }
        }
        converted.interactive.isQuantityOptional = true;
        converted.interactive.quantityRange.min = range.min;
        converted.interactive.quantityRange.max = range.max;
      }
      converted.isTaxExempt = item.taxExempt || false;
      return converted;
    };
    getVariableFromRate = function (rate, account) {
      var ref, ref1, ref2, variable, variables;
      variables =
        (ref = account.settings) != null
          ? (ref1 = ref.modules) != null
            ? (ref2 = ref1.quote) != null
              ? ref2.variables
              : void 0
            : void 0
          : void 0;
      if (!variables) {
        return null;
      }
      variable = _.find(variables, {
        name: rate.name,
        unitName: rate.unit,
        defaultAmount: rate.rate,
      });
      return variable;
    };
    return convertFromV1;
  };
}.call(this));
