// Generated by CoffeeScript 1.12.7
(function () {
  var navigationModel;

  navigationModel = require("./Navigation");

  module.exports = {
    name: "",
    type: "Splash",
    cssBlockName: "splash-module",
    resourceUrl: "",
    resourceType: "image",
    settings: {
      navigation: navigationModel,
      size: "half",
      withContent: true,
    },
    contentAreas: {
      main: {
        content: '<h2 placeholder="true"><br></h2>\n<p placeholder="true"><br></p>',
        widgets: {},
      },
    },
  };
}.call(this));
