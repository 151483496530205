"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lineBreak = void 0;
var Text_1 = require("./Text");
var Common_1 = require("ContentTokens/Models/Common");
exports.lineBreak = {
    createToken: function () {
        return {
            type: "inline",
            subType: "lineBreak",
        };
    },
    createSlateJson: function () {
        return {
            isVoid: true,
            type: Common_1.InlineType.LineBreak,
            children: [Text_1.text.createSlateJson("", {})],
        };
    },
};
