// Generated by CoffeeScript 1.12.7

/*
  Get Color as hex

  @param accountColors [Object] contains brand and complementary colors as hex's
  @param name [String] one of "brand", "complementary", "white", "slate", "silver", or "none"
  @param modifier [String] the modifier to be applied onto the color. E.g. "darken"
 */

(function () {
  var black, colorUtils;

  colorUtils = require("../ColorUtils/ColorUtils");

  black = "#111111";

  module.exports = function (accountColors, name, modifier) {
    var baseColor, nameMap;
    if (name === "none") {
      return name;
    } else {
      nameMap = {
        brand: accountColors.brand,
        complementary: accountColors.complementary,
        white: "#ffffff",
        slate: "#4a4a4a",
        silver: "rgba(129,162,178,.1)",
      };
      baseColor = nameMap[name];
      if (baseColor == null) {
        return null;
      } else if (modifier === "darken") {
        return colorUtils.mixColors(baseColor, black, 80);
      } else if (modifier === "translucent") {
        return colorUtils.fade(baseColor, 75);
      } else {
        return baseColor;
      }
    }
  };
}.call(this));
