"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi) {
    return (function () {
        function Search(placeholder, search, options) {
            var _this = this;
            this.search = search;
            this.clear = function () {
                _this._ui.input = "";
                _this.search(_this._ui.input, "clear");
            };
            options = options || {};
            var theme = options.theme || "normal";
            var size = options.size || "normal";
            var isFilled = function () {
                return _this._ui.input && _this._ui.input.length > 0;
            };
            var isFocused = false;
            this._ui = createUi(require("./Search.html"), {
                placeholder: placeholder,
                isFocused: function () {
                    return isFocused;
                },
                search: function (query, action) {
                    query = query || "";
                    search(query, action);
                },
                focus: function (query) {
                    isFocused = true;
                    query = query || "";
                    if (query.length === 0) {
                        search(query, "focus");
                    }
                },
                blur: function (query) {
                    isFocused = false;
                    query = query || "";
                    if (query.length === 0) {
                        search(query, "blur");
                    }
                },
                keypress: function (event, query) {
                    if (event.key.toLowerCase() === "enter") {
                        search(query, "enter");
                    }
                },
                size: size,
                theme: theme,
                isFilled: isFilled,
                clear: this.clear,
                input: "",
            });
        }
        Object.defineProperty(Search.prototype, "ui", {
            get: function () {
                return this._ui;
            },
            enumerable: false,
            configurable: true
        });
        return Search;
    }());
});
