"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi, $timeout) {
    return (function () {
        function InputAutoSize(className, value, onChange) {
            var _this = this;
            this._ui = createUi(require("./InputAutoSize.html"), {
                className: className,
                width: 100,
                value: value,
                onChange: function (text) {
                    if (onChange) {
                        onChange(text);
                    }
                    _this.ui.syncWidth();
                },
                syncWidth: function () {
                    _this.ui.inputCopy.text(_this.ui.value);
                    _this.ui.width = _this.ui.inputCopy.width() + 20;
                },
                focus: function () {
                    if (_this.ui.input) {
                        _this.ui.input.focus();
                    }
                },
                $onElementRendered: function (element, label) {
                    if (label === "input-copy") {
                        _this.ui.inputCopy = element;
                        $timeout(function () {
                            _this.ui.syncWidth();
                        });
                    }
                    else if (label === "input") {
                        _this.ui.input = element;
                    }
                },
            });
        }
        Object.defineProperty(InputAutoSize.prototype, "ui", {
            get: function () {
                return this._ui;
            },
            enumerable: false,
            configurable: true
        });
        return InputAutoSize;
    }());
});
