"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVideoPlayerTypeFromUrl = void 0;
var getVideoPlayerTypeFromUrl = function (url) {
    if (url.match(/^https?:\/\/((www\.)?youtube\.com|youtu\.be)/)) {
        return "youtube";
    }
    if (url.match(/^https?:\/\/((www\.)?vimeo\.com)/)) {
        return "vimeo";
    }
    return "html5";
};
exports.getVideoPlayerTypeFromUrl = getVideoPlayerTypeFromUrl;
