"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDisplayBlockType = exports.blockTypeMapping = void 0;
var commonTypes_1 = require("./commonTypes");
exports.blockTypeMapping = (_a = {},
    _a[commonTypes_1.BlockType.Quote] = "Quote",
    _a[commonTypes_1.BlockType.Source] = "Embed",
    _a);
var getDisplayBlockType = function (type) {
    if (type === commonTypes_1.BlockType.Quote || type === commonTypes_1.BlockType.Source) {
        return exports.blockTypeMapping[type];
    }
    else {
        return type;
    }
};
exports.getDisplayBlockType = getDisplayBlockType;
