// Generated by CoffeeScript 1.12.7
(function () {
  var Helpers, HtmlCleaner, Point, Range, Tokenizer, assert, isUrl;

  Tokenizer = require("./Tokenizer").default;

  Helpers = require("./Helpers");

  require("qed");

  assert = Helpers.assert;

  Point = qed.Point;

  Range = qed.Range;

  isUrl = function (str) {
    return /^https?:\/\/[^\s]+$/.test(str);
  };

  module.exports = HtmlCleaner = (function () {
    function HtmlCleaner(insertWidget) {
      var insertTokenizedData, shouldInheritBlockContext;
      this.cleanHtml = function (elem, outputPoint, pasteContextTag, containingElem) {
        var currentElem, end, endPoint, isIncompatible, last, start, tokenizer, tokens;
        if (pasteContextTag == null) {
          pasteContextTag = "p";
        }
        start = Point.start(elem);
        end = Point.end(elem);
        tokenizer = new Tokenizer(new Range(start, end));
        tokens = tokenizer.tokens;
        this.tokens = tokens;
        if (tokens.length === 0) {
          return outputPoint;
        }
        last = tokens[tokens.length - 1];
        isIncompatible = false;
        if (last != null && last.type === "block") {
          tokens.splice(tokens.length - 1, 1);
          if (tokens.length) {
            if (tokens[0].type !== "block") {
              tokens.splice(0, 0, tokenizer.createBasicBlockToken(pasteContextTag.toLowerCase()));
            }
            isIncompatible = (tokens[0].blockType || "p") !== pasteContextTag.toLowerCase();
          }
          if (!isIncompatible) {
            tokens.splice(0, 1);
          }
        } else if (last.type === "widget") {
          tokens.push({
            type: "block",
          });
          isIncompatible = true;
        }
        endPoint = insertTokenizedData(isIncompatible, tokens, outputPoint, containingElem);
        currentElem = containingElem.firstElementChild;
        while (currentElem != null) {
          if (currentElem.tagName.toLowerCase() !== "insertable") {
            qed.util.ensureOpen(currentElem);
          }
          currentElem = currentElem.nextElementSibling;
        }
        return endPoint;
      };
      insertTokenizedData = (function (_this) {
        return function (insertingIncompatible, tokens, insertionPoint, containingElem) {
          var blockElem,
            container,
            createBlockElem,
            createTextItem,
            getToTop,
            i,
            idx,
            incompatible,
            insertBulletAtTopWhileMerging,
            isEmpty,
            isInCompatibleGroupContainer,
            joinAfter,
            len,
            processTextToken,
            shouldInheritBlock,
            tagMatches,
            tok,
            wrap;
          if (tokens.length === 0) {
            return insertionPoint;
          }
          shouldInheritBlock = !insertingIncompatible && shouldInheritBlockContext(tokens);
          assert(!(shouldInheritBlock && insertingIncompatible));
          getToTop = function () {
            var results;
            results = [];
            while (insertionPoint.containingElement() !== containingElem) {
              if (!insertionPoint.nodeAfter()) {
                results.push(insertionPoint.splitRight(false));
              } else {
                results.push(insertionPoint.splitLeft(false));
              }
            }
            return results;
          };
          isEmpty = function (obj) {
            var k;
            for (k in obj) {
              return false;
            }
            return true;
          };
          createTextItem = function (tok) {
            var k, ref, textNode, v, wrapper;
            textNode = document.createTextNode(tok.text);
            if (tok.attrs.link == null && isUrl(tok.text)) {
              tok.attrs.link = tok.text;
            }
            if (isEmpty(tok.attrs)) {
              return textNode;
            }
            if (tok.attrs.link) {
              wrapper = document.createElement("a");
            } else {
              wrapper = document.createElement("span");
            }
            ref = tok.attrs;
            for (k in ref) {
              v = ref[k];
              Helpers.inlineAttributes[k].write(wrapper, v);
            }
            wrapper.appendChild(textNode);
            return wrapper;
          };
          wrap = function (parentTag, child) {
            var container;
            container = document.createElement(parentTag);
            container.appendChild(child);
            return container;
          };
          createBlockElem = function (tok) {
            var elem;
            return (elem = document.createElement(tok.blockType || "p"));
          };
          isInCompatibleGroupContainer = function (insertionPoint, tok) {
            var container;
            container = insertionPoint.containingElement();
            return container !== containingElem && container.tagName.toLowerCase() === tok.bulletStyle;
          };
          tagMatches = function (name, elem) {
            var ref;
            return (
              (elem != null ? ((ref = elem.tagName) != null ? ref.toLowerCase() : void 0) : void 0) ===
              name.toLowerCase()
            );
          };
          processTextToken = function (tok) {
            var node;
            node = createTextItem(tok);
            insertionPoint.insert(node);
            return (insertionPoint = Point.after(node));
          };
          if (insertingIncompatible) {
            joinAfter = false;
            if (tokens[0].type !== "block" && tokens[0].type !== "widget") {
              throw new Error("first must be block or widget when incompatible");
            }
            while (insertionPoint.containingElement() !== containingElem) {
              incompatible = insertionPoint.containingElement();
              if (incompatible.textContent === "") {
                insertionPoint = Point.after(incompatible).rightNormalized();
                incompatible.parentNode.removeChild(incompatible);
              } else {
                break;
              }
            }
            getToTop();
          }
          for (idx = i = 0, len = tokens.length; i < len; idx = ++i) {
            tok = tokens[idx];
            switch (tok.type) {
              case "text":
                assert(insertionPoint.containingElement() !== containingElem);
                processTextToken(tok);
                break;
              case "widget":
                getToTop();
                insertionPoint = insertWidget(tok.model, insertionPoint);
                break;
              case "block":
                blockElem = createBlockElem(tok);
                container = insertionPoint.containingElement();
                insertBulletAtTopWhileMerging = function () {
                  var matchesAfter, matchesBefore;
                  matchesBefore = tagMatches(tok.bulletStyle, insertionPoint.nodeBefore());
                  matchesAfter = tagMatches(tok.bulletStyle, insertionPoint.nodeAfter());
                  if (matchesBefore && matchesAfter) {
                    insertionPoint.joinLeft();
                    return insertionPoint.insert(blockElem);
                  } else if (matchesAfter) {
                    insertionPoint = Point.start(insertionPoint.nodeAfter());
                    return insertionPoint.insert(blockElem);
                  } else if (matchesBefore) {
                    insertionPoint = Point.end(insertionPoint.nodeBefore());
                    return insertionPoint.insert(blockElem);
                  } else {
                    return insertionPoint.insert(wrap(tok.bulletStyle, blockElem));
                  }
                };
                if (container === containingElem) {
                  assert(
                    !shouldInheritBlock || insertionPoint.leftNormalized().node.tagName.toLowerCase() === "insertable",
                  );
                  if (tok.blockType === "li") {
                    insertBulletAtTopWhileMerging();
                  } else {
                    if (shouldInheritBlock) {
                      blockElem = insertionPoint.node;
                    } else {
                      insertionPoint.insert(blockElem);
                    }
                  }
                } else {
                  if (shouldInheritBlock) {
                    insertionPoint.splitRight(true);
                    blockElem = insertionPoint.nodeAfter();
                  } else {
                    insertionPoint.splitRight(blockElem);
                    insertionPoint = Point.after(blockElem).rightNormalized();
                    if (tok.blockType === "li") {
                      if (!isInCompatibleGroupContainer(insertionPoint, tok)) {
                        getToTop();
                        insertBulletAtTopWhileMerging();
                      }
                    } else {
                      if (insertionPoint.containingElement() !== containingElem) {
                        insertionPoint.splitRight(false);
                        insertionPoint.insert(blockElem);
                      }
                    }
                  }
                }
                insertionPoint = Point.start(blockElem);
                break;
              default:
                throw new Error("unknown token type: " + tok.type);
            }
          }
          return insertionPoint;
        };
      })(this);
      shouldInheritBlockContext = function (tokens) {
        var i, len, tok;
        for (i = 0, len = tokens.length; i < len; i++) {
          tok = tokens[i];
          if (tok.blockType) {
            return false;
          }
        }
        return true;
      };
    }

    return HtmlCleaner;
  })();
}.call(this));
