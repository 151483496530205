"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_POST_ACCEPTANCE_REDIRECT_LABEL = exports.isPostAcceptanceActionType = exports.isPostAcceptanceAccepterType = exports.PostAcceptanceAction = exports.createEmptyPostAcceptanceSettings = exports.PostAcceptanceAccepters = void 0;
var PostAcceptanceAccepters;
(function (PostAcceptanceAccepters) {
    PostAcceptanceAccepters["LastAccepter"] = "lastAccepter";
    PostAcceptanceAccepters["AllAccepters"] = "allAccepters";
})(PostAcceptanceAccepters || (exports.PostAcceptanceAccepters = PostAcceptanceAccepters = {}));
var PostAcceptanceAction;
(function (PostAcceptanceAction) {
    PostAcceptanceAction["Redirect"] = "redirect";
})(PostAcceptanceAction || (exports.PostAcceptanceAction = PostAcceptanceAction = {}));
var DEFAULT_POST_ACCEPTANCE_REDIRECT_LABEL = "Continue";
exports.DEFAULT_POST_ACCEPTANCE_REDIRECT_LABEL = DEFAULT_POST_ACCEPTANCE_REDIRECT_LABEL;
var createEmptyPostAcceptanceSettings = function () { return ({
    enabled: false,
    action: PostAcceptanceAction.Redirect,
    accepters: PostAcceptanceAccepters.AllAccepters,
    url: "",
    continueLabel: DEFAULT_POST_ACCEPTANCE_REDIRECT_LABEL,
}); };
exports.createEmptyPostAcceptanceSettings = createEmptyPostAcceptanceSettings;
var isPostAcceptanceAccepterType = function (accepter) {
    return !!accepter && Object.values(PostAcceptanceAccepters).indexOf(accepter) >= 0;
};
exports.isPostAcceptanceAccepterType = isPostAcceptanceAccepterType;
var isPostAcceptanceActionType = function (action) {
    return !!action && Object.values(PostAcceptanceAction).indexOf(action) >= 0;
};
exports.isPostAcceptanceActionType = isPostAcceptanceActionType;
