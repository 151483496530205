// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    makeSafeForCss: function (s) {
      s = s.replace(/[\.,-\/#!$%\^&\*;\?:{}=\-_`~()]/g, "");
      s = s.replace(/[^-\w\s]/g, "");
      s = s.replace(/^\s+|\s+$/g, "");
      s = s.replace(/[-\s]+/g, "-");
      return (s = s.toLowerCase());
    },
  };
}.call(this));
