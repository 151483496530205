"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        version: 1,
        background: "slate",
        columnWidth: "regular",
        className: "paragraphs-impact-slate-left",
        name: "Impact Slate Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 20.3,
                ratio: 1.37974,
            },
            h2: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "regular",
        className: "paragraphs-impact-brand-left",
        name: "Impact Brand Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 20.3,
                ratio: 1.37974,
            },
            h2: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "silver",
        columnWidth: "large",
        className: "highlights-brand-on-silver-centered",
        name: "Brand on Silver Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "slate",
        columnWidth: "large",
        className: "highlights-slate-centered",
        name: "Slate Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "large",
        className: "highlights-complementary-centered",
        name: "Complementary Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "large",
        className: "highlights-brand-centered",
        name: "Brand Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "highlights-slate-on-white-centered",
        name: "Slate on White Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "slate",
                modifier: "darken",
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "highlights-brand-on-white-centered",
        name: "Brand on White Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-brand-combo",
        name: "Brand Combo",
        backgroundCard: "brand",
        tint: "brand",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-complimentary-combo",
        name: "Complimentary Combo",
        backgroundCard: "complementary",
        tint: "complementary",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-white-combo",
        name: "White Combo",
        backgroundCard: "white",
        tint: "white",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "brand",
                modifier: "darken",
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-slate-combo",
        name: "Slate Combo",
        backgroundCard: "slate",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-brand-pop",
        name: "Brand Pop",
        backgroundCard: "brand",
        tint: "white",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-complimentary-pop",
        name: "Complimentary Pop",
        backgroundCard: "complementary",
        tint: "white",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "darken",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-monochrome-white-pop",
        name: "Monochrome White Pop",
        backgroundCard: "white",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "slate",
                modifier: "darken",
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "regular",
        className: "pullouts-monochrome-slate-pop",
        name: "Monochrome Slate Pop",
        backgroundCard: "slate",
        tint: "white",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18,
            ratio: 1.409,
            spacing: 0,
        },
        blockTypes: ["Splash"],
        tag: "Pullouts",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-brand-impact-quote",
        name: "Brand Impact Quote",
        backgroundCard: "none",
        tint: "brand",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21.1,
            ratio: 1.618,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-complementary-impact-quote",
        name: "Complementary Impact Quote",
        backgroundCard: "none",
        tint: "complementary",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21.1,
            ratio: 1.618,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-slate-impact-quote",
        name: "Slate Impact Quote",
        backgroundCard: "none",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21.1,
            ratio: 1.618,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-brand-quote",
        name: "Brand Quote",
        backgroundCard: "none",
        tint: "brand",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 17.1,
            ratio: 1.618,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-complementary-quote",
        name: "Complementary Quote",
        backgroundCard: "none",
        tint: "complementary",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 17.1,
            ratio: 1.618,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-slate-quote",
        name: "Slate Quote",
        backgroundCard: "none",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 17.1,
            ratio: 1.618,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-brand-subtle-quote",
        name: "Brand Subtle Quote",
        backgroundCard: "none",
        tint: "brand",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.41736,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-complementary-subtle-quote",
        name: "Complementary Subtle Quote",
        backgroundCard: "none",
        tint: "complementary",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.41736,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "testimonials-slate-subtle-quote",
        name: "Slate Subtle Quote",
        backgroundCard: "none",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.41736,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Testimonials",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "headers-brand-impact",
        name: "Brand Impact",
        backgroundCard: "none",
        tint: "brand",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 22.4,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                ratio: 1.3254,
                scale: 19,
            },
            h2: {
                ratio: 1.3254,
                scale: 19,
            },
            h1: {
                ratio: 1.618,
                scale: 26,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "headers-complementary-impact",
        name: "Complementary Impact",
        backgroundCard: "none",
        tint: "complementary",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 22.4,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                ratio: 1.3254,
                scale: 19,
            },
            h2: {
                ratio: 1.3254,
                scale: 19,
            },
            h1: {
                ratio: 1.618,
                scale: 26,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "headers-slate-impact",
        name: "Slate Impact",
        backgroundCard: "none",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 22.4,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                ratio: 1.3254,
                scale: 19,
            },
            h2: {
                ratio: 1.3254,
                scale: 19,
            },
            h1: {
                ratio: 1.618,
                scale: 26,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "headers-brand-standard-left",
        name: "Brand Standard Left",
        backgroundCard: "none",
        tint: "brand",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: null,
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21,
            ratio: 1.49678,
            spacing: 0.3,
            p: {
                ratio: 1.3254,
                scale: 19,
            },
            h2: {
                ratio: 1.3254,
                scale: 19,
            },
            h1: {
                ratio: 1.4926000000000001,
                scale: 23,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "medium",
        className: "headers-brand-standard",
        name: "Brand Standard",
        backgroundCard: "none",
        tint: "brand",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: null,
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21,
            ratio: 1.49678,
            spacing: 0.3,
            p: {
                ratio: 1.3254,
                scale: 19,
            },
            h2: {
                ratio: 1.3254,
                scale: 19,
            },
            h1: {
                ratio: 1.4926000000000001,
                scale: 23,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "headers-complementary-standard-left",
        name: "Complementary Standard Left",
        backgroundCard: "none",
        tint: "complementary",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: null,
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21,
            ratio: 1.49678,
            spacing: 0.3,
            p: {
                ratio: 1.3254,
                scale: 19,
            },
            h2: {
                ratio: 1.3254,
                scale: 19,
            },
            h1: {
                ratio: 1.4926000000000001,
                scale: 23,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "medium",
        className: "headers-complementary-standard",
        name: "Complementary Standard",
        backgroundCard: "none",
        tint: "complementary",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: null,
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21,
            ratio: 1.49678,
            spacing: 0.3,
            p: {
                ratio: 1.3254,
                scale: 19,
            },
            h2: {
                ratio: 1.3254,
                scale: 19,
            },
            h1: {
                ratio: 1.4926000000000001,
                scale: 23,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "large",
        className: "headers-slate-standard-left",
        name: "Slate Standard Left",
        backgroundCard: "none",
        tint: "slate",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: null,
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21,
            ratio: 1.49678,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19,
                ratio: 1.3254,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "medium",
        className: "headers-slate-standard",
        name: "Slate Standard",
        backgroundCard: "none",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: null,
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 21,
            ratio: 1.49678,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19,
                ratio: 1.3254,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "medium",
        className: "headers-brand-subtle",
        name: "Brand Subtle",
        backgroundCard: "none",
        tint: "brand",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.41736,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "medium",
        className: "headers-complementary-subtle",
        name: "Complementary Subtle",
        backgroundCard: "none",
        tint: "complementary",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.41736,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "none",
        columnWidth: "medium",
        className: "headers-slate-subtle",
        name: "Slate Subtle",
        backgroundCard: "none",
        tint: "slate",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.41736,
            spacing: 0.5,
        },
        blockTypes: ["Splash"],
        tag: "Headers",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "highlights-brand-on-white-left",
        name: "Brand on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "highlights-slate-on-white-left",
        name: "Slate on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "slate",
                modifier: "darken",
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "large",
        className: "highlights-white-on-brand-left",
        name: "White on Brand Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "large",
        className: "highlights-white-on-complementary-left",
        name: "White on Complementary Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "slate",
        columnWidth: "large",
        className: "highlights-white-on-slate-left",
        name: "White on Slate Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "silver",
        columnWidth: "large",
        className: "highlights-brand-on-silver-left",
        name: "Brand on Silver Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 26,
            ratio: 1.2,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Highlights",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "regular",
        className: "paragraphs-impact-brand-on-white-left",
        name: "Impact Brand on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 20.3,
                ratio: 1.37974,
            },
            h2: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "regular",
        className: "paragraphs-impact-slate-on-white-left",
        name: "Impact Slate on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "slate",
                modifier: "darken",
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 20.3,
                ratio: 1.37974,
            },
            h2: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "regular",
        className: "paragraphs-impact-complementary-left",
        name: "Impact Complementary Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 20.3,
                ratio: 1.37974,
            },
            h2: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "regular",
        className: "paragraphs-standard-brand-on-white-left",
        name: "Standard Brand on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "regular",
        className: "paragraphs-subtle-brand-on-white-left",
        name: "Subtle Brand on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
            p: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h2: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h1: {
                scale: 20.1,
                ratio: 1.37138,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "regular",
        className: "paragraphs-standard-brand-left",
        name: "Standard Brand Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "regular",
        className: "paragraphs-standard-complementary-left",
        name: "Standard Complementary Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "slate",
        columnWidth: "regular",
        className: "paragraphs-standard-slate-left",
        name: "Standard Slate Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "regular",
        className: "paragraphs-subtle-brand-left",
        name: "Subtle Brand Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
            p: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h2: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h1: {
                scale: 20.1,
                ratio: 1.37138,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "regular",
        className: "paragraphs-subtle-complementary-left",
        name: "Subtle Complementary Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
            p: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h2: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h1: {
                scale: 20.1,
                ratio: 1.37138,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "slate",
        columnWidth: "regular",
        className: "paragraphs-subtle-slate-left",
        name: "Subtle Slate Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 18.9,
            ratio: 1.4926000000000001,
            spacing: 0.3,
            p: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h2: {
                scale: 18.9,
                ratio: 1.32122,
            },
            h1: {
                scale: 20.1,
                ratio: 1.37138,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Paragraphs",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "large",
        className: "titles-impact-brand-left",
        name: "Impact Brand Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 25.2,
                ratio: 1.5845600000000002,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "large",
        className: "titles-impact-brand-centered",
        name: "Impact Brand Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 25.2,
                ratio: 1.5845600000000002,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "large",
        className: "titles-impact-complementary-left",
        name: "Impact Complementary Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 25.2,
                ratio: 1.5845600000000002,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "large",
        className: "titles-impact-complementary-centered",
        name: "Impact Complementary Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 25.2,
                ratio: 1.5845600000000002,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "titles-impact-brand-on-white-centered",
        name: "Impact Brand on White Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 25.2,
                ratio: 1.5845600000000002,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "titles-standard-brand-on-white-centered",
        name: "Standard Brand on White Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "titles-impact-brand-on-white-left",
        name: "Impact Brand on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 25.2,
                ratio: 1.5845600000000002,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "large",
        className: "titles-standard-brand-on-white-left",
        name: "Standard Brand on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: null,
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "medium",
        className: "titles-standard-brand-left",
        name: "Standard Brand Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "medium",
        className: "titles-standard-brand-centered",
        name: "Standard Brand Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "medium",
        className: "titles-standard-complementary-centered",
        name: "Standard Complementary Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "medium",
        className: "titles-standard-complementary-left",
        name: "Standard Complementary Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 23,
            ratio: 1.618,
            spacing: 0.3,
            p: {
                scale: 19,
                ratio: 1.3254,
            },
            h2: {
                scale: 19.1,
                ratio: 1.32958,
            },
            h1: {
                scale: 23,
                ratio: 1.4926000000000001,
            },
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "regular",
        className: "titles-subtle-complementary-left",
        name: "Subtle Complementary Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.409,
            spacing: 0.51,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "complementary",
        columnWidth: "regular",
        className: "titles-subtle-complementary-centered",
        name: "Subtle Complementary Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.409,
            spacing: 0.51,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "regular",
        className: "titles-subtle-brand-centered",
        name: "Subtle Brand Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.409,
            spacing: 0.51,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "brand",
        columnWidth: "regular",
        className: "titles-subtle-brand-left",
        name: "Subtle Brand Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "white",
                modifier: "translucent",
            },
            h2: {
                name: "white",
                modifier: null,
            },
            h1: {
                name: "white",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.409,
            spacing: 0.51,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "regular",
        className: "titles-subtle-brand-on-white-left",
        name: "Subtle Brand on White Left",
        alignment: {
            h1: "left",
            h2: "left",
            p: "left",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.409,
            spacing: 0.51,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
    {
        version: 1,
        background: "white",
        columnWidth: "regular",
        className: "titles-subtle-brand-on-white-centered",
        name: "Subtle Brand on White Centered",
        alignment: {
            h1: "center",
            h2: "center",
            p: "center",
        },
        colors: {
            p: {
                name: "slate",
                modifier: null,
            },
            h2: {
                name: "slate",
                modifier: "darken",
            },
            h1: {
                name: "brand",
                modifier: null,
            },
        },
        fontStyles: {
            scale: 16,
            ratio: 1.409,
            spacing: 0.51,
        },
        blockTypes: ["Text", "Video", "Source"],
        tag: "Titles",
        isFactoryStyle: true,
    },
];
