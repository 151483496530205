// Generated by CoffeeScript 1.12.7

/*
  Get Margin

  @param fontStyles [Object] the font style as defined on the style object
  @param element [String] one of "h1", "h2", "p"
 */

(function () {
  var addPx,
    elementToMarginMap,
    getAsTwoDecimalPlace,
    getHeadingMargin,
    getInsertableMargin,
    getParagraphMargin,
    getSubheadingMargin;

  getAsTwoDecimalPlace = function (value) {
    return Math.round(value * 100) / 100;
  };

  getHeadingMargin = function (scale, ratio, spacing) {
    return addPx(getAsTwoDecimalPlace(scale * ratio * ratio * spacing));
  };

  getSubheadingMargin = function (scale, ratio, spacing) {
    return addPx(getAsTwoDecimalPlace(scale * ratio * spacing));
  };

  getParagraphMargin = function () {
    return 0;
  };

  getInsertableMargin = function (scale, ratio, spacing) {
    return addPx(getAsTwoDecimalPlace(scale * ratio * ratio * spacing)) + " 0";
  };

  addPx = function (num) {
    return num + "px";
  };

  elementToMarginMap = {
    h1: function (scale, ratio, spacing) {
      return getHeadingMargin(scale, ratio, spacing);
    },
    h2: function (scale, ratio, spacing) {
      return getSubheadingMargin(scale, ratio, spacing);
    },
    p: function (scale, ratio, spacing) {
      return getParagraphMargin(scale, ratio, spacing);
    },
    insertable: function (scale, ratio, spacing) {
      return getInsertableMargin(scale, ratio, spacing);
    },
  };

  module.exports = function (fontStyles, element) {
    var ratio, ref, scale, spacing;
    spacing = fontStyles.spacing;
    if (_.isEmpty(fontStyles[element])) {
      (scale = fontStyles.scale), (ratio = fontStyles.ratio);
    } else {
      (ref = fontStyles[element]), (scale = ref.scale), (ratio = ref.ratio);
    }
    return elementToMarginMap[element](scale, ratio, spacing);
  };
}.call(this));
