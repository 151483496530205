"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentThreadResolvedOrigin = exports.CommentThreadOpenedOrigin = exports.MentionType = void 0;
var MentionType;
(function (MentionType) {
    MentionType["External"] = "external";
    MentionType["Internal"] = "internal";
})(MentionType || (exports.MentionType = MentionType = {}));
var CommentThreadOpenedOrigin;
(function (CommentThreadOpenedOrigin) {
    CommentThreadOpenedOrigin["EmailLink"] = "email link";
    CommentThreadOpenedOrigin["AppEditor"] = "app editor";
    CommentThreadOpenedOrigin["Page"] = "page";
    CommentThreadOpenedOrigin["SharedLink"] = "shared link";
    CommentThreadOpenedOrigin["AppNotification"] = "app notification";
})(CommentThreadOpenedOrigin || (exports.CommentThreadOpenedOrigin = CommentThreadOpenedOrigin = {}));
var CommentThreadResolvedOrigin;
(function (CommentThreadResolvedOrigin) {
    CommentThreadResolvedOrigin["Checkbox"] = "checkbox";
    CommentThreadResolvedOrigin["OptionsMenu"] = "options menu";
    CommentThreadResolvedOrigin["Replied"] = "replied";
})(CommentThreadResolvedOrigin || (exports.CommentThreadResolvedOrigin = CommentThreadResolvedOrigin = {}));
