// Generated by CoffeeScript 1.12.7

/*
  The data model for the Quote module.
 */

(function () {
  var navigationModel;

  navigationModel = require("./Navigation");

  module.exports = {
    name: "",
    type: "Quote",
    cssBlockName: "quote-module",
    total: 0,
    totalWithTaxes: 0,
    taxes: [],
    settings: {
      navigation: navigationModel,
      displayConfig: {
        total: true,
        subTotal: true,
        taxTotal: false,
        units: true,
        sectionSubTotals: false,
      },
      accept: {
        esignEnabled: false,
        active: true,
        text: "Accept Quote",
        successMsg: "Congratulations! The quote has been accepted.",
      },
      customLabels: {
        total: "Total",
        subtotal: "Subtotal",
        excludingTax: "Ex. Tax",
        per: "per",
        discount: "Discount",
      },
    },
    sections: [],
    discount: {
      type: "percent",
      amount: 0,
    },
    contentAreas: {
      header: {
        content: '<h2 placeholder="true"><br></h2>\n<p placeholder="true"><br></p>',
        widgets: {},
      },
    },
  };
}.call(this));
