"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteCombinedPlansAndTable = exports.QuotePlansOnly = exports.QuoteTableOnly = void 0;
var QuoteV2Presets_1 = require("./QuoteV2Presets");
var navigationModel = require("./Navigation");
var base = {
    name: "",
    type: "QuoteV2",
    cssBlockName: "quote-v2-module",
    settings: {
        navigation: navigationModel,
        currency: null,
        view: {
            showTotalDiscount: false,
            showTaxTotals: false,
            showExTax: false,
            showQuoteTotals: true,
            showSectionIds: false,
            showDecimalPlaces: true,
        },
    },
    sections: [],
    discount: {
        enabled: false,
        type: "percent",
        units: 0,
    },
    selectionType: "combined",
    includeRecurringInDueToday: true,
    contentAreas: {
        header: {
            content: "<h2 placeholder=\"true\"><br></h2>\n<p placeholder=\"true\"><br></p>",
            widgets: {},
        },
    },
};
exports.default = base;
exports.QuoteTableOnly = __assign(__assign({}, base), { sections: QuoteV2Presets_1.QuotePresetTableOnly });
exports.QuotePlansOnly = __assign(__assign({}, base), { sections: QuoteV2Presets_1.QuotePresetPlansOnly });
exports.QuoteCombinedPlansAndTable = __assign(__assign({}, base), { sections: QuoteV2Presets_1.QuotePresetCombo });
