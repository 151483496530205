"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenIntegrationVerificationStatus = void 0;
var TokenIntegrationVerificationStatus;
(function (TokenIntegrationVerificationStatus) {
    TokenIntegrationVerificationStatus["VERIFY_SUCCESS"] = "VERIFY_SUCCESS";
    TokenIntegrationVerificationStatus["ENABLE_INTEGRATION"] = "ENABLE_INTEGRATION";
    TokenIntegrationVerificationStatus["REQUEST_TO_ENABLE_INTEGRATION"] = "REQUEST_TO_ENABLE_INTEGRATION";
    TokenIntegrationVerificationStatus["AUTHORIZE_INTEGRATION"] = "AUTHORIZE_INTEGRATION";
})(TokenIntegrationVerificationStatus || (exports.TokenIntegrationVerificationStatus = TokenIntegrationVerificationStatus = {}));
