"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.titleCase = exports.pluraliseWord = exports.pluralise = exports.arrayToReadableString = void 0;
var lodash_1 = require("lodash");
var arrayToReadableString = function (array, lastSeparator) {
    if (lastSeparator === void 0) { lastSeparator = "and"; }
    if (array.length === 0) {
        return "";
    }
    else if (array.length === 1) {
        return array[0];
    }
    else {
        return "".concat(array.slice(0, array.length - 1).join(", "), " ").concat(lastSeparator, " ").concat(array[array.length - 1]);
    }
};
exports.arrayToReadableString = arrayToReadableString;
var pluralise = function (num, phrase, pluralPhrase, wordForZero) {
    wordForZero = wordForZero || "0";
    var getNumber = (0, lodash_1.isNumber)(num) ? function () { return num; } : num;
    var pluralisedWord = (0, exports.pluraliseWord)(getNumber(), phrase, pluralPhrase);
    if (getNumber() === 0) {
        return "".concat(wordForZero, " ").concat(pluralisedWord);
    }
    else {
        return "".concat(getNumber(), " ").concat(pluralisedWord);
    }
};
exports.pluralise = pluralise;
var pluraliseWord = function (num, phrase, pluralPhrase) {
    pluralPhrase = pluralPhrase || "".concat(phrase, "s");
    if (num === 1) {
        return "".concat(phrase);
    }
    else {
        return "".concat(pluralPhrase);
    }
};
exports.pluraliseWord = pluraliseWord;
var titleCase = function (text) { return text[0].toUpperCase() + text.substring(1); };
exports.titleCase = titleCase;
exports.default = {
    arrayToReadableString: exports.arrayToReadableString,
    pluralise: exports.pluralise,
    pluraliseWord: exports.pluraliseWord,
};
