"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCrmToken = exports.isCrmProviderType = exports.isHubSpotObjectType = void 0;
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var CRM_PROVIDER_TYPES = [
    Types_1.ProviderType.Hubspot,
    Types_1.ProviderType.Salesforce,
    Types_1.ProviderType.Sfdc,
    Types_1.ProviderType.Zoho,
    Types_1.ProviderType.LegitRamen,
    Types_1.ProviderType.Pipedrive,
];
var isCrmProviderType = function (providerType) {
    return CRM_PROVIDER_TYPES.indexOf(providerType) >= 0;
};
exports.isCrmProviderType = isCrmProviderType;
var isHubSpotObjectType = function (objectType) {
    return Object.values(Types_1.HubspotObjectType).indexOf(objectType) >= 0;
};
exports.isHubSpotObjectType = isHubSpotObjectType;
var isCrmToken = function (token) {
    return isCrmProviderType(token.providerType);
};
exports.isCrmToken = isCrmToken;
