"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateEffectiveFolderPermissions = void 0;
var Util_1 = require("@CommonFrontendBackend/Permissions/Util");
var calculateEffectiveFolderPermissions = function (folders) {
    var e_1, _a, e_2, _b, e_3, _c;
    var _d, _e, _f;
    var effectivePermissions = {
        teams: {},
        users: {},
    };
    try {
        for (var folders_1 = __values(folders), folders_1_1 = folders_1.next(); !folders_1_1.done; folders_1_1 = folders_1.next()) {
            var folder = folders_1_1.value;
            var permissions = folder.permissions;
            if ((0, Util_1.isGreater)(permissions.account, (_d = effectivePermissions.account) === null || _d === void 0 ? void 0 : _d.action)) {
                effectivePermissions.account = {
                    folderId: folder.id,
                    folderName: folder.name,
                    action: permissions.account,
                };
            }
            try {
                for (var _g = (e_2 = void 0, __values(Object.entries(permissions.teams))), _h = _g.next(); !_h.done; _h = _g.next()) {
                    var _j = __read(_h.value, 2), teamId = _j[0], action = _j[1];
                    if ((0, Util_1.isGreater)(action, (_e = effectivePermissions.teams[teamId]) === null || _e === void 0 ? void 0 : _e.action)) {
                        effectivePermissions.teams[teamId] = {
                            folderId: folder.id,
                            folderName: folder.name,
                            action: action,
                        };
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                }
                finally { if (e_2) throw e_2.error; }
            }
            try {
                for (var _k = (e_3 = void 0, __values(Object.entries(permissions.users))), _l = _k.next(); !_l.done; _l = _k.next()) {
                    var _m = __read(_l.value, 2), userId = _m[0], action = _m[1];
                    if ((0, Util_1.isGreater)(action, (_f = effectivePermissions.users[userId]) === null || _f === void 0 ? void 0 : _f.action)) {
                        effectivePermissions.users[userId] = {
                            folderId: folder.id,
                            folderName: folder.name,
                            action: action,
                        };
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (folders_1_1 && !folders_1_1.done && (_a = folders_1.return)) _a.call(folders_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return effectivePermissions;
};
exports.calculateEffectiveFolderPermissions = calculateEffectiveFolderPermissions;
