"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NonImageMediaSourceList = exports.MetricSource = exports.MediaSource = void 0;
var MediaSource;
(function (MediaSource) {
    MediaSource["FastUpload"] = "FastUpload";
    MediaSource["StockImages"] = "stockImages";
    MediaSource["StockVideo"] = "stockVideo";
    MediaSource["Unsplash"] = "unsplash";
    MediaSource["Upload"] = "upload";
    MediaSource["UploadedImage"] = "uploadedImage";
    MediaSource["UnsplashCollection"] = "unsplashCollection";
    MediaSource["Youtube"] = "youtube";
    MediaSource["Explore"] = "explore";
})(MediaSource || (exports.MediaSource = MediaSource = {}));
var MetricSource;
(function (MetricSource) {
    MetricSource["Drag"] = "drag";
    MetricSource["Clipboard"] = "clipboard";
    MetricSource["Carousel"] = "carousel";
    MetricSource["Collection"] = "collection";
    MetricSource["StockImage"] = "stock image";
    MetricSource["RecentlyUsed"] = "recently used";
    MetricSource["SearchExisting"] = "search existing";
    MetricSource["SearchGallery"] = "search gallery";
    MetricSource["SearchCarousel"] = "search carousel";
    MetricSource["UploadExisting"] = "upload existing";
    MetricSource["UploadGallery"] = "upload gallery";
    MetricSource["UploadCarousel"] = "upload carousel";
    MetricSource["Sidebar"] = "sidebar";
})(MetricSource || (exports.MetricSource = MetricSource = {}));
exports.NonImageMediaSourceList = [MediaSource.UnsplashCollection, MediaSource.StockVideo, MediaSource.Youtube];
