// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (imageLocation) {
    var currences, imgFile;
    imgFile = function (filename) {
      if (imageLocation) {
        return imageLocation.local("flags/" + filename + ".svg");
      } else {
        return "";
      }
    };
    return (currences = [
      {
        name: "US Dollar",
        code: "USD",
        country: "United States",
        flagName: "us",
        flagImage: imgFile("us"),
        symbol: "$",
      },
      {
        name: "Euro",
        code: "EUR",
        country: "Europe",
        flagName: "eu",
        flagImage: imgFile("eu"),
        symbol: "€",
      },
      {
        name: "Japanese Yen",
        code: "JPY",
        country: "Japan",
        flagName: "jp",
        flagImage: imgFile("jp"),
        symbol: "￥",
      },
      {
        name: "British Pound Sterling",
        code: "GBP",
        country: "United Kingdom",
        flagName: "gb",
        flagImage: imgFile("gb"),
        symbol: "£",
      },
      {
        name: "Australian Dollar",
        code: "AUD",
        country: "Australia",
        flagName: "au",
        flagImage: imgFile("au"),
        symbol: "$",
      },
      {
        name: "Swiss Franc",
        code: "CHF",
        country: "Switzerland",
        flagName: "ch",
        flagImage: imgFile("ch"),
        symbol: "CHF ",
      },
      {
        name: "Canadian Dollar",
        code: "CAD",
        country: "Canada",
        flagName: "ca",
        flagImage: imgFile("ca"),
        symbol: "$",
      },
      {
        name: "Mexican Peso",
        code: "MXN",
        country: "Mexico",
        flagName: "mx",
        flagImage: imgFile("mx"),
        symbol: "$",
      },
      {
        name: "Chinese Yuan",
        code: "CNY",
        country: "China",
        flagName: "cn",
        flagImage: imgFile("cn"),
        symbol: "CN¥",
      },
      {
        name: "New Zealand Dollar",
        code: "NZD",
        country: "New Zealand",
        flagName: "nz",
        flagImage: imgFile("nz"),
        symbol: "$",
      },
      {
        name: "Swedish Krona",
        code: "SEK",
        country: "Sweden",
        flagName: "se",
        flagImage: imgFile("se"),
        symbol: "kr",
      },
      {
        name: "Russian Ruble",
        code: "RUB",
        country: "Russia",
        flagName: "ru",
        flagImage: imgFile("ru"),
        symbol: "руб.",
      },
      {
        name: "Afghan Afghani",
        code: "AFN",
        country: "Afghanistan",
        flagName: "af",
        flagImage: imgFile("af"),
        symbol: "؋",
      },
      {
        name: "Albanian Lek",
        code: "ALL",
        country: "Albania",
        flagName: "al",
        flagImage: imgFile("al"),
        symbol: "Lek",
      },
      {
        name: "Algerian Dinar",
        code: "DZD",
        country: "Algeria",
        flagName: "dz",
        flagImage: imgFile("dz"),
        symbol: "د.ج.‏",
      },
      {
        name: "Angolan Kwanza",
        code: "AOA",
        country: "Angola",
        flagName: "ao",
        flagImage: imgFile("ao"),
        symbol: "Kz",
      },
      {
        name: "Antillean Guilder",
        code: "ANG",
        country: "Curaçao",
        flagName: "cw",
        flagImage: imgFile("cw"),
        symbol: "NAƒ",
      },
      {
        name: "Argentine Peso",
        code: "ARS",
        country: "Argentina",
        flagName: "ar",
        flagImage: imgFile("ar"),
        symbol: "$",
      },
      {
        name: "Armenian Dram",
        code: "AMD",
        country: "Armenia",
        flagName: "am",
        flagImage: imgFile("am"),
        symbol: "դր.",
      },
      {
        name: "Azerbaijani Manat",
        code: "AZN",
        country: "Azerbaijan",
        flagName: "az",
        flagImage: imgFile("az"),
        symbol: "ман.",
      },
      {
        name: "Bahamian Dollar",
        code: "BSD",
        country: "Bahamas",
        flagName: "bs",
        flagImage: imgFile("bs"),
        symbol: "B$",
      },
      {
        name: "Bahraini Dinar",
        code: "BHD",
        country: "Bahrain",
        flagName: "bh",
        flagImage: imgFile("bh"),
        symbol: "د.ب.‏",
      },
      {
        name: "Bangladeshi Taka",
        code: "BDT",
        country: "Bangladesh",
        flagName: "bd",
        flagImage: imgFile("bd"),
        symbol: "৳",
      },
      {
        name: "Belarusian Ruble",
        code: "BYR",
        country: "Belarus",
        flagName: "by",
        flagImage: imgFile("by"),
        symbol: "BYR",
      },
      {
        name: "Belize Dollar",
        code: "BZD",
        country: "Belize",
        flagName: "bz",
        flagImage: imgFile("bz"),
        symbol: "$",
      },
      {
        name: "Bolivian Boliviano",
        code: "BOB",
        country: "Bolivia",
        flagName: "bo",
        flagImage: imgFile("bo"),
        symbol: "Bs",
      },
      {
        name: "Bosnia-Herzegovina Convertible Mark",
        code: "BAM",
        country: "Bosnia and Herzegovina",
        flagName: "ba",
        flagImage: imgFile("ba"),
        symbol: "KM",
      },
      {
        name: "Botswanan Pula",
        code: "BWP",
        country: "Botswana",
        flagName: "bw",
        flagImage: imgFile("bw"),
        symbol: "P",
      },
      {
        name: "Brazilian Real",
        code: "BRL",
        country: "Brazil",
        flagName: "br",
        flagImage: imgFile("br"),
        symbol: "R$",
      },
      {
        name: "Brunei Dollar",
        code: "BND",
        country: "Brunei",
        flagName: "bn",
        flagImage: imgFile("bn"),
        symbol: "$",
      },
      {
        name: "Bulgarian Lev",
        code: "BGN",
        country: "Bulgaria",
        flagName: "bg",
        flagImage: imgFile("bg"),
        symbol: "лв.",
      },
      {
        name: "Burundian Franc",
        code: "BIF",
        country: "Burundi",
        flagName: "bi",
        flagImage: imgFile("bi"),
        symbol: "FBu",
      },
      {
        name: "Cambodian Riel",
        code: "KHR",
        country: "Cambodia",
        flagName: "kh",
        flagImage: imgFile("kh"),
        symbol: "៛",
      },
      {
        name: "Cape Verdean Escudo",
        code: "CVE",
        country: "Cape Verde",
        flagName: "cv",
        flagImage: imgFile("cv"),
        symbol: "CV$",
      },
      {
        name: "CFA Franc BEAC",
        code: "XAF",
        country: "Chad",
        flagName: "td",
        flagImage: imgFile("td"),
        symbol: "FCFA",
      },
      {
        name: "Chilean Peso",
        code: "CLP",
        country: "Chile",
        flagName: "cl",
        flagImage: imgFile("cl"),
        symbol: "$",
      },
      {
        name: "Colombian Peso",
        code: "COP",
        country: "Colombia",
        flagName: "co",
        flagImage: imgFile("co"),
        symbol: "$",
      },
      {
        name: "Comorian Franc",
        code: "KMF",
        country: "Comoros",
        flagName: "km",
        flagImage: imgFile("km"),
        symbol: "FC",
      },
      {
        name: "Costa Rican Colón",
        code: "CRC",
        country: "Costa Rica",
        flagName: "cr",
        flagImage: imgFile("cr"),
        symbol: "₡",
      },
      {
        name: "Croatian Kuna",
        code: "HRK",
        country: "Croatia",
        flagName: "hr",
        flagImage: imgFile("hr"),
        symbol: "kn",
      },
      {
        name: "Czech Republic Koruna",
        code: "CZK",
        country: "Czech Republic",
        flagName: "cz",
        flagImage: imgFile("cz"),
        symbol: "Kč",
      },
      {
        name: "Djiboutian Franc",
        code: "DJF",
        country: "Djibouti",
        flagName: "dj",
        flagImage: imgFile("dj"),
        symbol: "Fdj",
      },
      {
        name: "Dominican Peso",
        code: "DOP",
        country: "Dominican Republic",
        flagName: "do",
        flagImage: imgFile("do"),
        symbol: "RD$",
      },
      {
        name: "Congolese Franc",
        code: "CDF",
        country: "DR Congo",
        flagName: "cd",
        flagImage: imgFile("cd"),
        symbol: "FrCD",
      },
      {
        name: "Egyptian Pound",
        code: "EGP",
        country: "Egypt",
        flagName: "eg",
        flagImage: imgFile("eg"),
        symbol: "ج.م.‏",
      },
      {
        name: "Eritrean Nakfa",
        code: "ERN",
        country: "Eritrea",
        flagName: "er",
        flagImage: imgFile("er"),
        symbol: "Nfk",
      },
      {
        name: "Ethiopian Birr",
        code: "ETB",
        country: "Ethiopia",
        flagName: "et",
        flagImage: imgFile("et"),
        symbol: "Br",
      },
      {
        name: "Georgian Lari",
        code: "GEL",
        country: "Georgia",
        flagName: "ge",
        flagImage: imgFile("ge"),
        symbol: "GEL",
      },
      {
        name: "Ghanaian Cedi",
        code: "GHS",
        country: "Ghana",
        flagName: "gh",
        flagImage: imgFile("gh"),
        symbol: "GH₵",
      },
      {
        name: "Danish Krone",
        code: "DKK",
        country: "Denmark",
        flagName: "dk",
        flagImage: imgFile("dk"),
        symbol: "kr",
      },
      {
        name: "Guatemalan Quetzal",
        code: "GTQ",
        country: "Guatemala",
        flagName: "gt",
        flagImage: imgFile("gt"),
        symbol: "Q",
      },
      {
        name: "Guinean Franc",
        code: "GNF",
        country: "Guinea",
        flagName: "gn",
        flagImage: imgFile("gn"),
        symbol: "FG",
      },
      {
        name: "Honduran Lempira",
        code: "HNL",
        country: "Honduras",
        flagName: "hn",
        flagImage: imgFile("hn"),
        symbol: "L",
      },
      {
        name: "Hong Kong Dollar",
        code: "HKD",
        country: "Hong Kong",
        flagName: "hk",
        flagImage: imgFile("hk"),
        symbol: "$",
      },
      {
        name: "Hungarian Forint",
        code: "HUF",
        country: "Hungary",
        flagName: "hu",
        flagImage: imgFile("hu"),
        symbol: "Ft",
      },
      {
        name: "Icelandic Króna",
        code: "ISK",
        country: "Iceland",
        flagName: "is",
        flagImage: imgFile("is"),
        symbol: "kr",
      },
      {
        name: "Indian Rupee",
        code: "INR",
        country: "India",
        flagName: "in",
        flagImage: imgFile("in"),
        symbol: "₹",
      },
      {
        name: "Indonesian Rupiah",
        code: "IDR",
        country: "Indonesia",
        flagName: "id",
        flagImage: imgFile("id"),
        symbol: "Rp",
      },
      {
        name: "Iranian Rial",
        code: "IRR",
        country: "Iran",
        flagName: "ir",
        flagImage: imgFile("ir"),
        symbol: "﷼",
      },
      {
        name: "Iraqi Dinar",
        code: "IQD",
        country: "Iraq",
        flagName: "iq",
        flagImage: imgFile("iq"),
        symbol: "د.ع.‏",
      },
      {
        name: "Israeli New Sheqel",
        code: "ILS",
        country: "Israel",
        flagName: "il",
        flagImage: imgFile("il"),
        symbol: "₪",
      },
      {
        name: "Jamaican Dollar",
        code: "JMD",
        country: "Jamaica",
        flagName: "jm",
        flagImage: imgFile("jm"),
        symbol: "$",
      },
      {
        name: "Jordanian Dinar",
        code: "JOD",
        country: "Jordan",
        flagName: "jo",
        flagImage: imgFile("jo"),
        symbol: "د.أ.‏",
      },
      {
        name: "Kazakhstani Tenge",
        code: "KZT",
        country: "Kazakhstan",
        flagName: "kz",
        flagImage: imgFile("kz"),
        symbol: "тңг.",
      },
      {
        name: "Kenyan Shilling",
        code: "KES",
        country: "Kenya",
        flagName: "ke",
        flagImage: imgFile("ke"),
        symbol: "Ksh",
      },
      {
        name: "Kuwaiti Dinar",
        code: "KWD",
        country: "Kuwait",
        flagName: "kw",
        flagImage: imgFile("kw"),
        symbol: "د.ك.‏",
      },
      {
        name: "Lebanese Pound",
        code: "LBP",
        country: "Lebanon",
        flagName: "lb",
        flagImage: imgFile("lb"),
        symbol: "ل.ل.‏",
      },
      {
        name: "Libyan Dinar",
        code: "LYD",
        country: "Libya",
        flagName: "ly",
        flagImage: imgFile("ly"),
        symbol: "د.ل.‏",
      },
      {
        name: "Macanese Pataca",
        code: "MOP",
        country: "Macau",
        flagName: "mo",
        flagImage: imgFile("mo"),
        symbol: "MOP$",
      },
      {
        name: "Macedonian Denar",
        code: "MKD",
        country: "Macedonia",
        flagName: "mk",
        flagImage: imgFile("mk"),
        symbol: "MKD",
      },
      {
        name: "Malagasy Ariary",
        code: "MGA",
        country: "Madagascar",
        flagName: "mg",
        flagImage: imgFile("mg"),
        symbol: "MGA",
      },
      {
        name: "Malaysian Ringgit",
        code: "MYR",
        country: "Malaysia",
        flagName: "my",
        flagImage: imgFile("my"),
        symbol: "RM",
      },
      {
        name: "Mauritian Rupee",
        code: "MUR",
        country: "Mauritius",
        flagName: "mu",
        flagImage: imgFile("mu"),
        symbol: "MURs",
      },
      {
        name: "Moldovan Leu",
        code: "MDL",
        country: "Moldova",
        flagName: "md",
        flagImage: imgFile("md"),
        symbol: "MDL",
      },
      {
        name: "Moroccan Dirham",
        code: "MAD",
        country: "Morocco",
        flagName: "ma",
        flagImage: imgFile("ma"),
        symbol: "د.م.‏",
      },
      {
        name: "Mozambican Metical",
        code: "MZN",
        country: "Mozambique",
        flagName: "mz",
        flagImage: imgFile("mz"),
        symbol: "MTn",
      },
      {
        name: "Myanma Kyat",
        code: "MMK",
        country: "Myanmar",
        flagName: "mm",
        flagImage: imgFile("mm"),
        symbol: "K",
      },
      {
        name: "Namibian Dollar",
        code: "NAD",
        country: "Namibia",
        flagName: "na",
        flagImage: imgFile("na"),
        symbol: "N$",
      },
      {
        name: "Nepalese Rupee",
        code: "NPR",
        country: "Nepal",
        flagName: "np",
        flagImage: imgFile("np"),
        symbol: "नेरू",
      },
      {
        name: "Nicaraguan Córdoba",
        code: "NIO",
        country: "Nicaragua",
        flagName: "ni",
        flagImage: imgFile("ni"),
        symbol: "C$",
      },
      {
        name: "CFA Franc BCEAO",
        code: "XOF",
        country: "Niger",
        flagName: "ne",
        flagImage: imgFile("ne"),
        symbol: "CFA",
      },
      {
        name: "Nigerian Naira",
        code: "NGN",
        country: "Nigeria",
        flagName: "ng",
        flagImage: imgFile("ng"),
        symbol: "₦",
      },
      {
        name: "Norwegian Krone",
        code: "NOK",
        country: "Norway",
        flagName: "no",
        flagImage: imgFile("no"),
        symbol: "kr",
      },
      {
        name: "Omani Rial",
        code: "OMR",
        country: "Oman",
        flagName: "om",
        flagImage: imgFile("om"),
        symbol: "ر.ع.‏",
      },
      {
        name: "Pakistani Rupee",
        code: "PKR",
        country: "Pakistan",
        flagName: "pk",
        flagImage: imgFile("pk"),
        symbol: "₨",
      },
      {
        name: "Panamanian Balboa",
        code: "PAB",
        country: "Panama",
        flagName: "pa",
        flagImage: imgFile("pa"),
        symbol: "B/.",
      },
      {
        name: "Papua New Guinean Kina",
        code: "PGK",
        country: "Papua New Guinea",
        flagName: "pg",
        flagImage: imgFile("pg"),
        symbol: "K",
      },
      {
        name: "Paraguayan Guarani",
        code: "PYG",
        country: "Paraguay",
        flagName: "py",
        flagImage: imgFile("py"),
        symbol: "₲",
      },
      {
        name: "Peruvian Nuevo Sol",
        code: "PEN",
        country: "Peru",
        flagName: "pe",
        flagImage: imgFile("pe"),
        symbol: "S/.",
      },
      {
        name: "Philippine Peso",
        code: "PHP",
        country: "Philippines",
        flagName: "ph",
        flagImage: imgFile("ph"),
        symbol: "₱",
      },
      {
        name: "Polish Zloty",
        code: "PLN",
        country: "Poland",
        flagName: "pl",
        flagImage: imgFile("pl"),
        symbol: "zł",
      },
      {
        name: "Qatari Rial",
        code: "QAR",
        country: "Qatar",
        flagName: "qa",
        flagImage: imgFile("qa"),
        symbol: "ر.ق.‏",
      },
      {
        name: "Romanian Leu",
        code: "RON",
        country: "Romania",
        flagName: "ro",
        flagImage: imgFile("ro"),
        symbol: "RON",
      },
      {
        name: "Rwandan Franc",
        code: "RWF",
        country: "Rwanda",
        flagName: "rw",
        flagImage: imgFile("rw"),
        symbol: "FR",
      },
      {
        name: "Saudi Riyal",
        code: "SAR",
        country: "Saudi Arabia",
        flagName: "sa",
        flagImage: imgFile("sa"),
        symbol: "ر.س.‏",
      },
      {
        name: "Serbian Dinar",
        code: "RSD",
        country: "Serbia",
        flagName: "rs",
        flagImage: imgFile("rs"),
        symbol: "дин.",
      },
      {
        name: "Singapore Dollar",
        code: "SGD",
        country: "Singapore",
        flagName: "sg",
        flagImage: imgFile("sg"),
        symbol: "$",
      },
      {
        name: "Somali Shilling",
        code: "SOS",
        country: "Somalia",
        flagName: "so",
        flagImage: imgFile("so"),
        symbol: "Ssh",
      },
      {
        name: "South African Rand",
        code: "ZAR",
        country: "South Africa",
        flagName: "za",
        flagImage: imgFile("za"),
        symbol: "R",
      },
      {
        name: "South Korean Won",
        code: "KRW",
        country: "South Korea",
        flagName: "kr",
        flagImage: imgFile("kr"),
        symbol: "₩",
      },
      {
        name: "Sri Lankan Rupee",
        code: "LKR",
        country: "Sri Lanka",
        flagName: "lk",
        flagImage: imgFile("lk"),
        symbol: "SL Re",
      },
      {
        name: "Sudanese Pound",
        code: "SDG",
        country: "Sudan",
        flagName: "sd",
        flagImage: imgFile("sd"),
        symbol: "SDG",
      },
      {
        name: "Syrian Pound",
        code: "SYP",
        country: "Syria",
        flagName: "sy",
        flagImage: imgFile("sy"),
        symbol: "ل.س.‏",
      },
      {
        name: "New Taiwan Dollar",
        code: "TWD",
        country: "Taiwan",
        flagName: "tw",
        flagImage: imgFile("tw"),
        symbol: "NT$",
      },
      {
        name: "Tanzanian Shilling",
        code: "TZS",
        country: "Tanzania",
        flagName: "tz",
        flagImage: imgFile("tz"),
        symbol: "TSh",
      },
      {
        name: "Thai Baht",
        code: "THB",
        country: "Thailand",
        flagName: "th",
        flagImage: imgFile("th"),
        symbol: "฿",
      },
      {
        name: "Tongan Paʻanga",
        code: "TOP",
        country: "Tonga",
        flagName: "to",
        flagImage: imgFile("to"),
        symbol: "T$",
      },
      {
        name: "Trinidad and Tobago Dollar",
        code: "TTD",
        country: "Trinidad and Tobago",
        flagName: "tt",
        flagImage: imgFile("tt"),
        symbol: "$",
      },
      {
        name: "Tunisian Dinar",
        code: "TND",
        country: "Tunisia",
        flagName: "tn",
        flagImage: imgFile("tn"),
        symbol: "د.ت.‏",
      },
      {
        name: "Turkish Lira",
        code: "TRY",
        country: "Turkey",
        flagName: "tr",
        flagImage: imgFile("tr"),
        symbol: "TL",
      },
      {
        name: "Ugandan Shilling",
        code: "UGX",
        country: "Uganda",
        flagName: "ug",
        flagImage: imgFile("ug"),
        symbol: "USh",
      },
      {
        name: "Ukrainian Hryvnia",
        code: "UAH",
        country: "Ukraine",
        flagName: "ua",
        flagImage: imgFile("ua"),
        symbol: "₴",
      },
      {
        name: "United Arab Emirates Dirham",
        code: "AED",
        country: "United Arab Emirates",
        flagName: "ae",
        flagImage: imgFile("ae"),
        symbol: "د.إ.‏",
      },
      {
        name: "Uruguayan Peso",
        code: "UYU",
        country: "Uruguay",
        flagName: "uy",
        flagImage: imgFile("uy"),
        symbol: "$",
      },
      {
        name: "Uzbekistan Som",
        code: "UZS",
        country: "Uzbekistan",
        flagName: "uz",
        flagImage: imgFile("uz"),
        symbol: "UZS",
      },
      {
        name: "Venezuelan Bolívar",
        code: "VEF",
        country: "Venezuela",
        flagName: "ve",
        flagImage: imgFile("ve"),
        symbol: "Bs.F.",
      },
      {
        name: "Vietnamese Dong",
        code: "VND",
        country: "Vietnam",
        flagName: "vn",
        flagImage: imgFile("vn"),
        symbol: "₫",
      },
      {
        name: "Yemeni Rial",
        code: "YER",
        country: "Yemen",
        flagName: "ye",
        flagImage: imgFile("ye"),
        symbol: "ر.ي.‏",
      },
    ]);
  };
}.call(this));
