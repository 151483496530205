"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectContentOrigin = exports.ProjectCreationMethod = exports.ProjectCreationUiOrigin = exports.ProjectCreationSource = void 0;
var ProjectCreationSource;
(function (ProjectCreationSource) {
    ProjectCreationSource["Salesforce"] = "salesforce";
    ProjectCreationSource["Hubspot"] = "hubspot";
    ProjectCreationSource["Zapier"] = "zapier";
    ProjectCreationSource["PublicApi"] = "public api";
    ProjectCreationSource["FoundryApi"] = "foundry api";
    ProjectCreationSource["DocConverter"] = "doc converter";
    ProjectCreationSource["CsvBlueprint"] = "csv blueprint";
    ProjectCreationSource["Ui"] = "ui";
    ProjectCreationSource["MarketingWebsite"] = "marketing website";
    ProjectCreationSource["Unknown"] = "unknown";
    ProjectCreationSource["PageBuilderAI"] = "page builder ai";
    ProjectCreationSource["SignUp"] = "sign up";
})(ProjectCreationSource || (exports.ProjectCreationSource = ProjectCreationSource = {}));
var ProjectCreationUiOrigin;
(function (ProjectCreationUiOrigin) {
    ProjectCreationUiOrigin["Dashboard"] = "dashboard";
    ProjectCreationUiOrigin["PageDashboardBanner"] = "page dashboard banner";
    ProjectCreationUiOrigin["Editor"] = "editor";
    ProjectCreationUiOrigin["Search"] = "search";
    ProjectCreationUiOrigin["TemplateGallery"] = "template gallery";
    ProjectCreationUiOrigin["Onboarding"] = "onboarding";
    ProjectCreationUiOrigin["Unknown"] = "unknown";
})(ProjectCreationUiOrigin || (exports.ProjectCreationUiOrigin = ProjectCreationUiOrigin = {}));
var ProjectCreationMethod;
(function (ProjectCreationMethod) {
    ProjectCreationMethod["New"] = "new";
    ProjectCreationMethod["Clone"] = "clone";
    ProjectCreationMethod["Convert"] = "convert";
    ProjectCreationMethod["FromCode"] = "from code";
    ProjectCreationMethod["FromTemplate"] = "from template";
    ProjectCreationMethod["FromSavedBlocks"] = "from saved blocks";
    ProjectCreationMethod["FromPageBuilderAI"] = "generated by page builder ai";
})(ProjectCreationMethod || (exports.ProjectCreationMethod = ProjectCreationMethod = {}));
var ProjectContentOrigin;
(function (ProjectContentOrigin) {
    ProjectContentOrigin["PublicTemplate"] = "public template";
    ProjectContentOrigin["OnboardingTemplate"] = "onboarding template";
    ProjectContentOrigin["UserTemplate"] = "user template";
    ProjectContentOrigin["UserSavedBlock"] = "user saved block";
    ProjectContentOrigin["UserPage"] = "user page";
    ProjectContentOrigin["UserDocxFile"] = "user docx file";
    ProjectContentOrigin["Blank"] = "blank";
    ProjectContentOrigin["Unknown"] = "unknown";
    ProjectContentOrigin["PageBuilderAI"] = "page builder ai page";
    ProjectContentOrigin["HardcodedDataInCode"] = "hardcoded data in code";
})(ProjectContentOrigin || (exports.ProjectContentOrigin = ProjectContentOrigin = {}));
