// Generated by CoffeeScript 1.12.7

/*
  The loading controller is used as a wrapper around a UI that relies on getting some of its
  initial data asynchronously. The `futureUi` promise being passed in should resolve into a UI
  object to be displayed, if it rejects then the error message will displayed in place of the UI.

  As an example of use:

  createFooUi = (
    createUi
    userInfo
    someOtherInjectedModule
  ) ->
    return createUi require("./Foo.html"), {
      userInfo: userInfo
      doSomething: ->
        someOtherInjectModule.helper()
    }

  fetchAndCreateFoo = ->
    service.fetchUserData()
    .then (user) ->
      di.invoke creatFooUi, null, {
        userInfo: user
      }

  loadingController createFooUi()


  This is useful to simplifying the UI controlling code (in the example within `createFooUi`) so
  that it just worries about being given what it needs, rather than how to get it.
 */

(function () {
  var template;

  template = require("./LoadingController.html");

  module.exports = function (createUi) {
    return function (futureUi, options) {
      var ui;
      if (options == null) {
        options = {};
      }
      ui = createUi(template, {
        isLoading: true,
        loadingMessage: options.loadingMessage || "",
      });
      futureUi
        .then(function (resolvedUi) {
          ui.resolvedUi = resolvedUi;
          return (ui.isLoading = false);
        })
        ["catch"](function (err) {
          ui.errorMessage = err.message;
          return (ui.isLoading = false);
        });
      return ui;
    };
  };
}.call(this));
