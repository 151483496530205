"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compressionOptions = exports.pageSizeMapsForCompressionParams = exports.printCompression = exports.Compression = void 0;
var Compression;
(function (Compression) {
    Compression["Archive"] = "archive";
    Compression["Email"] = "email";
    Compression["Print"] = "print";
})(Compression || (exports.Compression = Compression = {}));
var archiveCompression = {
    label: "Archive",
    value: Compression.Archive,
    tooltipMessage: "Lowest quality images for the smallest file size, ideal for record keeping",
};
var emailCompression = {
    label: "Email",
    value: Compression.Email,
    tooltipMessage: "Small file size without compromising image quality, perfect for sharing by email",
};
exports.printCompression = {
    label: "Print",
    value: Compression.Print,
    tooltipMessage: "Highest quality images optimized for perfect prints",
};
exports.pageSizeMapsForCompressionParams = {
    A4: "a4",
    USPAPER: "letter",
};
exports.compressionOptions = [archiveCompression, emailCompression, exports.printCompression];
