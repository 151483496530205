// Generated by CoffeeScript 1.12.7

/*
  The module used to create SimpleDropdowns. It is meant to be super simple to insert; no options,
  bells, or whistles to worry about.

  If there are more than 10 elements, there is an added field for search.
 */

(function () {
  var SimpleDropdownFactory;

  module.exports = SimpleDropdownFactory = (function () {
    function SimpleDropdownFactory(createUi, OnClickOutside, make, noop, Search) {
      this.create = (function (_this) {
        return function (items, original, onSelect, options) {
          var onClickOutside, searchTerm, ui;
          if (onSelect == null) {
            onSelect = noop;
          }
          if (options == null) {
            options = {};
          }
          if (options.theme == null) {
            options.theme = "normal";
          }
          if (original != null) {
            original = _.find(items, original);
            if (original == null) {
              original = items[0];
            }
          } else {
            original = items[0];
          }
          onClickOutside = null;
          searchTerm = null;
          return (ui = createUi(require("./SimpleDropdown.html"), {
            items: items,
            selected: original,
            theme: options.theme,
            select: function (value) {
              ui.selected = value;
              ui.hide();
              return onSelect(value);
            },
            visible: false,
            hide: function () {
              if (onClickOutside != null) {
                onClickOutside.stopListening();
              }
              return (ui.visible = false);
            },
            show: function () {
              onClickOutside = make(OnClickOutside, {
                ignoreElems: [".simple-dropdown"],
                onClickOutside: function () {
                  ui.hide();
                  return onClickOutside.stopListening();
                },
              });
              if (!!options.getUpdatedItems) {
                ui.items = options.getUpdatedItems();
              }
              return (ui.visible = true);
            },
            toggle: function () {
              if (ui.visible) {
                return ui.hide();
              } else {
                return ui.show();
              }
            },
            isItemVisible: function (item) {
              if (!searchTerm) {
                return true;
              } else if (options.searchFn != null) {
                return options.searchFn(item, searchTerm);
              } else {
                return item.name.toLowerCase().includes(searchTerm);
              }
            },
            search: new Search(
              "Search",
              function (query) {
                if (query.length > 0) {
                  return (searchTerm = query.toLowerCase());
                } else {
                  return (searchTerm = null);
                }
              },
              {
                theme: "small",
              },
            ),
          }));
        };
      })(this);
    }

    return SimpleDropdownFactory;
  })();
}.call(this));
