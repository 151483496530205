"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findNearestValue = void 0;
var findNearestValue = function (providedValue, allowedValues) {
    return allowedValues.reduce(function (previousClosest, curr) {
        var currDelta = Math.abs(curr - providedValue);
        var prevDelta = Math.abs(previousClosest - providedValue);
        if (currDelta === prevDelta) {
            return curr < previousClosest ? curr : previousClosest;
        }
        return currDelta < prevDelta ? curr : previousClosest;
    });
};
exports.findNearestValue = findNearestValue;
