"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USE_SINGLE_TOUR_COMPATIBILITY_LAYER = exports.TourDefinitions = exports.TourId = exports.AutomationHubspotStepsId = exports.CreateAndEditTemplateTourStepsId = void 0;
var CreateAndEditTemplateTourStepsId;
(function (CreateAndEditTemplateTourStepsId) {
    CreateAndEditTemplateTourStepsId["WelcomeToQwilr"] = "welcome to qwilr";
    CreateAndEditTemplateTourStepsId["ChooseATemplate"] = "choose a template";
    CreateAndEditTemplateTourStepsId["EditText"] = "edit text";
    CreateAndEditTemplateTourStepsId["AddAnImage"] = "add an image";
    CreateAndEditTemplateTourStepsId["AddABlock"] = "add a block";
    CreateAndEditTemplateTourStepsId["ShareWithClients"] = "share with clients";
    CreateAndEditTemplateTourStepsId["ExploreTheDashboard"] = "explore the dashboard";
    CreateAndEditTemplateTourStepsId["CheckTheReports"] = "check the reports";
    CreateAndEditTemplateTourStepsId["IntroductionComplete"] = "introduction complete";
})(CreateAndEditTemplateTourStepsId || (exports.CreateAndEditTemplateTourStepsId = CreateAndEditTemplateTourStepsId = {}));
var AutomationHubspotStepsId;
(function (AutomationHubspotStepsId) {
    AutomationHubspotStepsId["WelcomeToQwilr"] = "welcome to qwilr";
    AutomationHubspotStepsId["ConnectToHubspot"] = "connect to hubspot";
    AutomationHubspotStepsId["CreateAPage"] = "create a page";
    AutomationHubspotStepsId["ConvertToTemplate"] = "convert to template";
    AutomationHubspotStepsId["AddTokens"] = "add tokens";
    AutomationHubspotStepsId["GeneratePage"] = "generate page";
    AutomationHubspotStepsId["AutomationTourComplete"] = "automation tour completed";
})(AutomationHubspotStepsId || (exports.AutomationHubspotStepsId = AutomationHubspotStepsId = {}));
var TourId;
(function (TourId) {
    TourId["CreateAndEditTemplate"] = "createAndEditTemplate";
    TourId["AutomationHubspot"] = "automationHubspot";
})(TourId || (exports.TourId = TourId = {}));
var createTourDefinition = function (config) { return config; };
exports.TourDefinitions = [
    createTourDefinition({
        id: TourId.CreateAndEditTemplate,
        steps: CreateAndEditTemplateTourStepsId,
    }),
    createTourDefinition({
        id: TourId.AutomationHubspot,
        steps: AutomationHubspotStepsId,
    }),
];
exports.USE_SINGLE_TOUR_COMPATIBILITY_LAYER = true;
