"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createButtonToken = exports.isButtonToken = void 0;
var isButtonToken = function (token) {
    return token.subType === "button";
};
exports.isButtonToken = isButtonToken;
var createButtonToken = function (text, alignment, href) {
    return {
        type: "block",
        subType: "button",
        text: text,
        alignment: alignment,
        href: href,
    };
};
exports.createButtonToken = createButtonToken;
