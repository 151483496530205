"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeMarketingAttributionData = void 0;
var decodeMarketingAttributionData = function (encodedAttributionString, base64ToString) {
    if (encodedAttributionString.startsWith("%2")) {
        var binString = base64ToString(encodedAttributionString.slice(2));
        var bytes = Uint8Array.from(binString, function (m) {
            return m.codePointAt(0);
        });
        return JSON.parse(new TextDecoder().decode(bytes));
    }
    else {
        return JSON.parse(base64ToString(encodedAttributionString));
    }
};
exports.decodeMarketingAttributionData = decodeMarketingAttributionData;
