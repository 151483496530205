"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFont = void 0;
var commonTypes_1 = require("./commonTypes");
exports.defaultFont = {
    friendlyName: "Open Sans",
    name: "Open Sans",
    typeface: commonTypes_1.FontTypeface.SansSerif,
    vendor: commonTypes_1.FontVendor.Google,
};
