"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MAX_SLUG_LENGTH = 2000;
exports.default = (function (projectName) {
    if (!projectName) {
        return null;
    }
    var friendlySlug = projectName
        .replace(/[^0-9a-zA-Z]+/g, "-")
        .replace(/\-+$/, "")
        .substring(0, MAX_SLUG_LENGTH);
    if (!isSlugValid(friendlySlug)) {
        return null;
    }
    return friendlySlug;
});
var isSlugValid = function (slug) {
    return ["", "-", "Untitled"].indexOf(slug) === -1;
};
