"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelinkExternalUserNotAllowedMessage = exports.getRelinkExternalUserConfirmationMessage = void 0;
var getRelinkExternalUserConfirmationMessage = function (integrationDisplayName) {
    return "This ".concat(integrationDisplayName, " user is already linked to a Qwilr user. If you continue, the existing link will be removed and you will be asked to reauthenticate your ").concat(integrationDisplayName, " account. Previously created pages may no longer be visible in the CRM but will remain in the Qwilr account.");
};
exports.getRelinkExternalUserConfirmationMessage = getRelinkExternalUserConfirmationMessage;
var getRelinkExternalUserNotAllowedMessage = function (integrationDisplayName) {
    return "This ".concat(integrationDisplayName, " user is already linked to a Qwilr user. Please reach out to your admin if this is incorrect. The current user will need to disable their integration for you to connect.");
};
exports.getRelinkExternalUserNotAllowedMessage = getRelinkExternalUserNotAllowedMessage;
