"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentCollaborationEvent = void 0;
var CommentCollaborationEvent;
(function (CommentCollaborationEvent) {
    CommentCollaborationEvent["Comment_Added"] = "comment_added";
    CommentCollaborationEvent["Comment_Deleted"] = "comment_deleted";
    CommentCollaborationEvent["Comment_Updated"] = "comment_updated";
    CommentCollaborationEvent["Thread_Deleted"] = "thread_deleted";
    CommentCollaborationEvent["Thread_Resolved"] = "thread_resolved";
    CommentCollaborationEvent["Thread_UnResolved"] = "thread_unresolved";
    CommentCollaborationEvent["Thread_Created"] = "thread_created";
})(CommentCollaborationEvent || (exports.CommentCollaborationEvent = CommentCollaborationEvent = {}));
