"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotePresetCombo = exports.QuotePresetPlansOnly = exports.QuotePresetTableOnly = void 0;
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var BillingScheduleOrchestrator_1 = require("QuoteV2/BillingSchedule/BillingScheduleOrchestrator");
var Section_1 = require("QuoteV2/Models/Section");
var lodash_1 = require("lodash");
var Text_1 = require("ContentTokens/DataModels/Text");
var ListItem_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/ListItem");
var Paragraph_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/Paragraph");
var Heading2_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/Heading2");
var billingSchedule = new BillingScheduleOrchestrator_1.BillingScheduleOrchestrator();
var setRecurringFrequency = function (item, frequency) {
    var clone = (0, lodash_1.cloneDeep)(item);
    billingSchedule.setRecurringFrequency(clone, frequency);
    return clone;
};
var planCard1 = {
    type: Types_1.QuoteItemType.FixedCost,
    description: {
        content: "",
        widgets: {},
        tokens: [(0, Paragraph_1.createParagraphToken)(), Text_1.text.createToken("Basic")],
    },
    quantity: 1,
    rate: {
        unit: "Users",
        rate: 6,
    },
    interactive: {
        isOptional: true,
        isOptionalSelected: false,
        isQuantityOptional: true,
        quantityRange: {
            min: 1,
            max: 10,
        },
    },
    isRecommended: false,
    featureList: {
        content: "",
        widgets: {},
        tokens: [
            (0, ListItem_1.createListItemToken)(),
            Text_1.text.createToken("Basic support"),
            (0, ListItem_1.createListItemToken)(),
            Text_1.text.createToken("Up to 5GB per month"),
        ],
    },
    isTaxExempt: false,
    discount: {
        enabled: false,
        type: Types_1.DiscountType.Percent,
        units: 0,
    },
};
var planCard2 = {
    type: Types_1.QuoteItemType.FixedCost,
    description: {
        content: "",
        widgets: {},
        tokens: [(0, Paragraph_1.createParagraphToken)(), Text_1.text.createToken("Standard")],
    },
    quantity: 1,
    rate: {
        unit: "Users",
        rate: 8,
    },
    interactive: {
        isOptional: true,
        isOptionalSelected: false,
        isQuantityOptional: true,
        quantityRange: {
            min: 1,
            max: 10,
        },
    },
    isRecommended: true,
    featureList: {
        content: "",
        widgets: {},
        tokens: [
            (0, ListItem_1.createListItemToken)(),
            Text_1.text.createToken("24h support"),
            (0, ListItem_1.createListItemToken)(),
            Text_1.text.createToken("Up to 10GB per month"),
        ],
    },
    isTaxExempt: false,
    discount: {
        enabled: false,
        type: Types_1.DiscountType.Percent,
        units: 0,
    },
};
var planCard3 = {
    type: Types_1.QuoteItemType.FixedCost,
    description: {
        content: "",
        widgets: {},
        tokens: [(0, Paragraph_1.createParagraphToken)(), Text_1.text.createToken("Premium")],
    },
    quantity: 1,
    rate: {
        unit: "Users",
        rate: 12,
    },
    interactive: {
        isOptional: true,
        isOptionalSelected: false,
        isQuantityOptional: true,
        quantityRange: {
            min: 1,
            max: 10,
        },
    },
    isRecommended: false,
    featureList: {
        content: "",
        widgets: {},
        tokens: [
            (0, ListItem_1.createListItemToken)(),
            Text_1.text.createToken("Priority 24h support"),
            (0, ListItem_1.createListItemToken)(),
            Text_1.text.createToken("Unlimited storage"),
            (0, ListItem_1.createListItemToken)(),
            Text_1.text.createToken("AI security monitoring"),
        ],
    },
    isTaxExempt: false,
    discount: {
        enabled: false,
        type: Types_1.DiscountType.Percent,
        units: 0,
    },
};
var tableItem1 = {
    type: Types_1.QuoteItemType.FixedCost,
    description: {
        content: "",
        widgets: {},
        tokens: [(0, Paragraph_1.createParagraphToken)(), Text_1.text.createToken("Onboarding")],
    },
    rate: {
        unit: "Users",
        rate: 500,
    },
    quantity: 1,
    interactive: {
        isOptional: true,
        isOptionalSelected: true,
        isQuantityOptional: false,
        quantityRange: {
            min: 0,
            max: 10,
        },
    },
    isRecommended: false,
    isTaxExempt: false,
    discount: {
        enabled: false,
        type: Types_1.DiscountType.Percent,
        units: 0,
    },
};
var tableItem2 = {
    type: Types_1.QuoteItemType.FixedCost,
    description: {
        content: "",
        widgets: {},
        tokens: [(0, Paragraph_1.createParagraphToken)(), Text_1.text.createToken("Setup fee")],
    },
    rate: {
        unit: "Users",
        rate: 250,
    },
    quantity: 1,
    interactive: {
        isOptional: true,
        isOptionalSelected: true,
        isQuantityOptional: false,
        quantityRange: {
            min: 0,
            max: 10,
        },
    },
    isTaxExempt: false,
    discount: {
        enabled: false,
        type: Types_1.DiscountType.Percent,
        units: 0,
    },
};
var monthly = [planCard1, planCard2, planCard3].map(function (item) {
    return setRecurringFrequency(item, Types_1.RecurringFrequency.Monthly);
});
var yearly = [planCard1, planCard2, planCard3].map(function (item) {
    item.discount = {
        enabled: true,
        type: Types_1.DiscountType.Percent,
        units: 20,
    };
    item.rate.rate = item.rate.rate * 12;
    return setRecurringFrequency(item, Types_1.RecurringFrequency.Yearly);
});
exports.QuotePresetTableOnly = [(0, Section_1.BaseSectionModel)(Types_1.QuoteSectionDisplayMode.Table)];
var planSection = (0, Section_1.BaseSectionModel)(Types_1.QuoteSectionDisplayMode.PlanCards, monthly);
planSection.description.tokens = [(0, Heading2_1.createHeading2Token)(), Text_1.text.createToken("Packages")];
exports.QuotePresetPlansOnly = [planSection];
var comboPlanSection = (0, Section_1.BaseSectionModel)(Types_1.QuoteSectionDisplayMode.PlanCards, __spreadArray(__spreadArray([], __read(monthly), false), __read(yearly), false));
comboPlanSection.viewSettings.groupItemsByBillingSchedule = true;
comboPlanSection.description.tokens = [(0, Heading2_1.createHeading2Token)(), Text_1.text.createToken("Packages")];
var comboTableSection = (0, Section_1.BaseSectionModel)(Types_1.QuoteSectionDisplayMode.Table, [tableItem1, tableItem2]);
comboTableSection.description.tokens = [(0, Heading2_1.createHeading2Token)(), Text_1.text.createToken("Add-ons")];
comboTableSection.viewSettings.showQuantity = false;
exports.QuotePresetCombo = [comboPlanSection, comboTableSection];
