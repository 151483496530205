"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var events_1 = require("events");
var lodash_1 = require("lodash");
var StripeElements = (function () {
    function StripeElements($log, stripeKey, stripeUserId, setErrorMsg, showEmailField, $q, createUi, environment, useUpdatedStyling) {
        var _this = this;
        this.$log = $log;
        this.stripeKey = stripeKey;
        this.stripeUserId = stripeUserId;
        this.setErrorMsg = setErrorMsg;
        this.showEmailField = showEmailField;
        this.useUpdatedStyling = useUpdatedStyling;
        this.stripeComponentWrappers = {};
        this.emitter = new events_1.EventEmitter();
        this.isDevMode = environment !== "production";
        var domLoading = $q.defer();
        var stripeScriptLoading = $q.defer();
        $q.all([domLoading.promise, stripeScriptLoading.promise]).then(function () {
            _this.cardSetup();
        });
        if (!Stripe) {
            $.getScript("https://js.stripe.com/v3/", function () {
                return stripeScriptLoading.resolve();
            });
        }
        else {
            stripeScriptLoading.resolve();
        }
        this.ui = createUi(require("./StripeElements.html"), {
            useUpdatedStyling: useUpdatedStyling,
            showEmailField: showEmailField,
            nameOnCard: "",
            email: "",
            zip: "",
            $init: function (scope) {
                _this.$scope = scope;
            },
            $onElementRendered: function () {
                domLoading.resolve();
            },
            nameChanged: this.onCustomFieldChange.bind(this),
            emailChanged: this.onCustomFieldChange.bind(this),
            zipChanged: this.onCustomFieldChange.bind(this),
            devFillCard: function () {
                if (_this.isDevMode) {
                    _this.useTestCard();
                }
            },
            stripeElementsReady: false,
        });
    }
    StripeElements.prototype.createCardInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.isDevMode && this.isUsingTestCard())) return [3, 1];
                        return [2, this.testCardInfo];
                    case 1:
                        if (!this.stripe) {
                            throw new Error("Stripe not initialised");
                        }
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4, this.stripe.createToken(this.card, {
                                name: this.ui.nameOnCard,
                                address_zip: this.ui.zip,
                            })];
                    case 3:
                        result = _a.sent();
                        if (result.error != null) {
                            throw result.error;
                        }
                        else {
                            return [2, this.parseCreatedToken(result.token)];
                        }
                        return [3, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.$log.error(err_1);
                        throw err_1;
                    case 5: return [2];
                }
            });
        });
    };
    StripeElements.prototype.handleCardPayment = function (paymentIntentSecret) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stripe) {
                            this.stripe = Stripe(this.stripeKey, {
                                apiVersion: "2024-09-30.acacia",
                            });
                        }
                        return [4, this.stripe.confirmCardPayment(paymentIntentSecret)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    StripeElements.prototype.validateForm = function () {
        if (this.isUsingTestCard()) {
            return true;
        }
        if (!this.ui.nameOnCard) {
            this.makeError("Please enter the cardholder name.", ".stripe-elements__name");
            return false;
        }
        else if (this.showEmailField && !this.ui.email) {
            this.makeError("Please enter the cardholder's contant email.", ".stripe-elements__email");
            return false;
        }
        else {
            var wrapperWithError = this.getStripeComponentWithError();
            if (wrapperWithError) {
                this.makeError(wrapperWithError.errorMessage, wrapperWithError.containerSelector);
                return false;
            }
        }
        return true;
    };
    StripeElements.prototype.isFormEmpty = function () {
        var e_1, _a;
        try {
            for (var _b = __values((0, lodash_1.values)(this.stripeComponentWrappers)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var wrapper = _c.value;
                if (!wrapper.isEmpty) {
                    return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (this.ui.nameOnCard || this.ui.email || this.ui.zip) {
            return false;
        }
        return true;
    };
    StripeElements.prototype.getEventEmitter = function () {
        return this.emitter;
    };
    StripeElements.prototype.useTestCard = function () {
        var e_2, _a;
        this.testCardInfo = TEST_CARD_INFO;
        this.ui.nameOnCard = this.testCardInfo.name;
        this.ui.email = this.testCardInfo.email;
        this.ui.zip = this.testCardInfo.zip;
        try {
            for (var _b = __values(Object.keys(this.stripeComponentWrappers)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var elementType = _c.value;
                var wrapper = this.stripeComponentWrappers[elementType];
                wrapper.rawComponent.clear();
                wrapper.rawComponent.update({
                    placeholder: DEV_FILL_PLACEHOLDERS[elementType],
                });
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    StripeElements.prototype.isUsingTestCard = function () {
        return !!this.testCardInfo;
    };
    StripeElements.prototype.cardSetup = function () {
        this.ui.loading = false;
        this.stripe = Stripe(this.stripeKey, {
            stripeAccount: this.stripeUserId ? this.stripeUserId : undefined,
            apiVersion: "2024-09-30.acacia",
        });
        this.elements = this.stripe.elements({
            fonts: [
                {
                    cssSrc: "https://use.typekit.net/mgh4sbk.css",
                },
            ],
        });
        this.card = this.createStripeComponent("cardNumber", ".stripe-elements__card", this.useUpdatedStyling ? "1234 1234 1234 1234" : "Card Number");
        this.createStripeComponent("cardExpiry", ".stripe-elements__expiry", "MM / YY");
        this.createStripeComponent("cardCvc", ".stripe-elements__cvc", "CVC");
    };
    StripeElements.prototype.getStripeComponentWithError = function () {
        var e_3, _a;
        try {
            for (var _b = __values((0, lodash_1.values)(this.stripeComponentWrappers)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var wrapper = _c.value;
                if (wrapper.errorMessage) {
                    return wrapper;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return null;
    };
    StripeElements.prototype.makeError = function (message, elementSelector) {
        this.externalErrorMessage = this.setErrorMsg(message, elementSelector);
        this.ui.errorField = elementSelector;
    };
    StripeElements.prototype.createStripeComponent = function (elementType, containerSelector, placeholder) {
        var _this = this;
        var options = { style: this.useUpdatedStyling ? UPDATED_ELEMENTS_APPEARANCE : ELEMENTS_STYLE, placeholder: placeholder };
        var rawComponent = this.elements.create(elementType, options);
        var stripeComponentWrapper = {
            rawComponent: rawComponent,
            placeholder: placeholder,
            containerSelector: containerSelector,
            errorMessage: undefined,
            isEmpty: true,
            isReady: false,
        };
        rawComponent.mount(containerSelector);
        rawComponent.on("change", function (event) {
            _this.onStripeElementsChange(event, stripeComponentWrapper);
        });
        rawComponent.on("ready", function () {
            var e_4, _a;
            stripeComponentWrapper.isReady = true;
            var allReady = true;
            try {
                for (var _b = __values((0, lodash_1.values)(_this.stripeComponentWrappers)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var wrapper = _c.value;
                    if (!wrapper.isReady) {
                        allReady = false;
                        break;
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_4) throw e_4.error; }
            }
            if (allReady) {
                _this.$scope.$apply(function () {
                    _this.ui.stripeElementsReady = allReady;
                });
            }
        });
        this.stripeComponentWrappers[elementType] = stripeComponentWrapper;
        return rawComponent;
    };
    StripeElements.prototype.onStripeElementsChange = function (event, stripeComponentWrapper) {
        this.emitter.emit("detailsChanged");
        this.hideExternalErrorMessage();
        this.clearDevPayment();
        var errorMessage = event.error ? event.error.message : null;
        stripeComponentWrapper.errorMessage = errorMessage;
        stripeComponentWrapper.isEmpty = event.empty;
    };
    StripeElements.prototype.onCustomFieldChange = function () {
        this.emitter.emit("detailsChanged");
        this.hideExternalErrorMessage();
        this.clearDevPayment();
    };
    StripeElements.prototype.clearDevPayment = function () {
        var e_5, _a;
        if (this.isUsingTestCard()) {
            try {
                for (var _b = __values(Object.keys(this.stripeComponentWrappers)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var elementType = _c.value;
                    var wrapper = this.stripeComponentWrappers[elementType];
                    wrapper.rawComponent.update({
                        placeholder: wrapper.placeholder,
                    });
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_5) throw e_5.error; }
            }
            this.testCardInfo = null;
        }
    };
    StripeElements.prototype.hideExternalErrorMessage = function () {
        if (!!this.externalErrorMessage && !!this.externalErrorMessage.hide) {
            this.externalErrorMessage.hide();
        }
    };
    StripeElements.prototype.parseCreatedToken = function (tokenObject) {
        var cardObject = tokenObject.card;
        return {
            token: tokenObject.id,
            name: cardObject.name,
            email: tokenObject.email,
            last4: cardObject.last4,
            brand: cardObject.brand,
            expiryMonth: cardObject.exp_month,
            expiryYear: cardObject.exp_year,
        };
    };
    return StripeElements;
}());
exports.default = StripeElements;
var TEST_CARD_INFO = {
    name: "Test Name",
    email: "testemail@testemail.testemail",
    zip: "1234",
    token: "tok_visa",
    last4: "4242",
    brand: "Visa",
    expiryMonth: 12,
    expiryYear: 2030,
};
var DEV_FILL_PLACEHOLDERS = {
    cardNumber: "4242424242424242",
    cardExpiry: "12 / 30",
    cardCvc: "123",
};
var ELEMENTS_STYLE = {
    base: {
        lineHeight: "55px",
        fontFamily: "'calibre', sans-serif",
        fontStyle: "normal",
        fontSize: "14px",
        color: "#737373",
        textTransform: "uppercase",
        "::placeholder": {
            fontSize: "12px",
            letterSpacing: "0.1em",
            color: "#B9B9B9",
        },
    },
};
var UPDATED_ELEMENTS_APPEARANCE = {
    base: {
        fontFamily: '"proxima-nova", sans-serif',
        fontSize: "16px",
        fontWeight: 600,
        color: "#242c39",
        lineHeight: "normal",
        "::placeholder": {
            color: "rgba(129, 162, 178, 0.5)",
        },
    },
};
