"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePassword = exports.ValidationError = exports.passwordThatMeetsRequirements = exports.PASSWORD_MAX_LENGTH = exports.PASSWORD_MIN_LENGTH = void 0;
exports.PASSWORD_MIN_LENGTH = 10;
exports.PASSWORD_MAX_LENGTH = 64;
var lowerCaseRegex = /[a-z]/;
var upperCaseRegex = /[A-Z]/;
var specialCharRegex = /[^A-Za-z0-9]/;
var numberRegex = /[0-9]/;
exports.passwordThatMeetsRequirements = "065@x5AV2fR$k^Qro";
var ValidationError;
(function (ValidationError) {
    ValidationError["Empty"] = "empty";
    ValidationError["TooShort"] = "tooShort";
    ValidationError["TooLong"] = "tooLong";
    ValidationError["InsufficientComplexity"] = "insufficientComplexity";
    ValidationError["PwnedPassword"] = "pwnedpassword";
})(ValidationError || (exports.ValidationError = ValidationError = {}));
var validatePassword = function (value) {
    if (!value || value.length === 0) {
        return { isValid: false, error: ValidationError.Empty };
    }
    if (value.length < exports.PASSWORD_MIN_LENGTH) {
        return { isValid: false, error: ValidationError.TooShort };
    }
    if (value.length > exports.PASSWORD_MAX_LENGTH) {
        return { isValid: false, error: ValidationError.TooLong };
    }
    var containsLowerUpperCase = !!lowerCaseRegex.test(value) && !!upperCaseRegex.test(value);
    var containsSpecialChar = !!specialCharRegex.test(value);
    var containsNumber = !!numberRegex.test(value);
    var meetsComplexityReq = containsLowerUpperCase && (containsNumber || containsSpecialChar);
    if (meetsComplexityReq === false) {
        return { isValid: false, error: ValidationError.InsufficientComplexity };
    }
    return { isValid: true };
};
exports.validatePassword = validatePassword;
