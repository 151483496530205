"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi) {
    return (function () {
        function EditableHeading(placeholder, canEditHeading, editHeadingFn, value, label, hideIcon) {
            var _this = this;
            this._ui = createUi(require("./EditableHeading.html"), {
                placeholder: placeholder,
                canEditHeading: canEditHeading,
                value: value,
                onHeadingChange: function () {
                    if (editHeadingFn) {
                        editHeadingFn(_this._ui.value);
                    }
                },
                label: label,
                hideIcon: hideIcon,
            });
        }
        Object.defineProperty(EditableHeading.prototype, "ui", {
            get: function () {
                return this._ui;
            },
            enumerable: false,
            configurable: true
        });
        return EditableHeading;
    }());
});
