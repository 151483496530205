"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockCollaborationEvent = exports.ProjectCollaborationEvent = void 0;
var ProjectCollaborationEvent;
(function (ProjectCollaborationEvent) {
    ProjectCollaborationEvent["Project_Opened"] = "project_opened";
    ProjectCollaborationEvent["Project_Updated"] = "project_updated";
    ProjectCollaborationEvent["Project_Deleted"] = "project_deleted";
    ProjectCollaborationEvent["Project_Sort_Changed"] = "project_sort_changed";
    ProjectCollaborationEvent["Project_Accepted"] = "project_accepted";
})(ProjectCollaborationEvent || (exports.ProjectCollaborationEvent = ProjectCollaborationEvent = {}));
var BlockCollaborationEvent;
(function (BlockCollaborationEvent) {
    BlockCollaborationEvent["Block_Locked"] = "block_locked";
    BlockCollaborationEvent["Block_Updated"] = "block_updated";
    BlockCollaborationEvent["Block_Deleted"] = "block_deleted";
})(BlockCollaborationEvent || (exports.BlockCollaborationEvent = BlockCollaborationEvent = {}));
