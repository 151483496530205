"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi, publicApiBaseUrl) {
    return createUi(require("./SingleSignOn.html"), {
        integrations: [
            {
                name: "google",
                link: "".concat(publicApiBaseUrl, "/auth/google/"),
                img: "/Assets/icons/sso/google-logo.svg",
            },
            {
                name: "salesforce",
                link: "".concat(publicApiBaseUrl, "/auth/salesforce/?sso=true"),
                img: "/Assets/icons/sso/salesforce-logo.svg",
            },
            {
                name: "microsoft",
                link: "".concat(publicApiBaseUrl, "/auth/microsoft/"),
                img: "/Assets/icons/sso/microsoft-logo.svg",
            },
        ].map(function (integration) {
            return createUi(require("./SSOButton/SSOButton.html"), integration);
        }),
    });
});
