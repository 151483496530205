"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var correctLink = function (link) {
    var linkProtocol = /^https?:\/\/|^\/\//i;
    var smsProtocol = /^sms:/i;
    var mailToProtocol = /^mailto:/i;
    var telProtocol = /^tel:/i;
    if (link.length == 0) {
        return link;
    }
    else if (linkProtocol.test(link)) {
        return link;
    }
    else if (smsProtocol.test(link)) {
        return link;
    }
    else if (mailToProtocol.test(link)) {
        return link;
    }
    else if (telProtocol.test(link)) {
        return link;
    }
    else {
        return "//" + link;
    }
};
exports.default = correctLink;
