"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnboardingTemplateType = exports.UserRoleOptionId = exports.CrmOptionId = void 0;
var CrmOptionId;
(function (CrmOptionId) {
    CrmOptionId["Salesforce"] = "salesforce";
    CrmOptionId["Hubspot"] = "hubspot";
    CrmOptionId["CustomBuilt"] = "custom-built";
    CrmOptionId["Zoho"] = "zoho-crm";
    CrmOptionId["Pipedrive"] = "pipedrive";
    CrmOptionId["None"] = "none";
    CrmOptionId["Other"] = "other";
})(CrmOptionId || (exports.CrmOptionId = CrmOptionId = {}));
var UserRoleOptionId;
(function (UserRoleOptionId) {
    UserRoleOptionId["MarketingManager"] = "marketing-manager";
    UserRoleOptionId["Sales"] = "sales";
    UserRoleOptionId["AccountManager"] = "account-manager";
    UserRoleOptionId["Operations"] = "operations";
    UserRoleOptionId["ExecutiveLeadership"] = "executive-leadership";
    UserRoleOptionId["Designer"] = "designer";
    UserRoleOptionId["Other"] = "other";
})(UserRoleOptionId || (exports.UserRoleOptionId = UserRoleOptionId = {}));
var OnboardingTemplateType;
(function (OnboardingTemplateType) {
    OnboardingTemplateType["SalesProposal"] = "Sales Proposal";
    OnboardingTemplateType["SalesQuote"] = "Sales Quote";
    OnboardingTemplateType["DigitalMarketingProposal"] = "Digital Marketing Proposal";
})(OnboardingTemplateType || (exports.OnboardingTemplateType = OnboardingTemplateType = {}));
