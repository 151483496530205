"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIntegrationHelpLink = exports.returnLearnMoreLink = void 0;
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var returnLearnMoreLink = function (integration, requiresTemplateAnchor) {
    if (requiresTemplateAnchor === void 0) { requiresTemplateAnchor = false; }
    var templateAnchor = "#template";
    return (0, exports.getIntegrationHelpLink)(integration) + requiresTemplateAnchor ? templateAnchor : "";
};
exports.returnLearnMoreLink = returnLearnMoreLink;
var getIntegrationHelpLink = function (integration) {
    switch (integration) {
        case Types_1.ProviderType.Zoho:
            return "https://help.qwilr.com/article/759-adding-zoho-crm-tokens-to-qwilr-pages";
        case Types_1.ProviderType.Pipedrive:
            return "https://help.qwilr.com/article/803-pipedrive-crm-integration-pipedrive-marketplace";
        default:
            throw Error("Unrecognised integration: ".concat(integration));
    }
};
exports.getIntegrationHelpLink = getIntegrationHelpLink;
