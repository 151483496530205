"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var TokenFormStore = (function () {
    function TokenFormStore() {
        this.formFields = {};
    }
    TokenFormStore.prototype.getById = function (id) {
        return this.formFields[id] || "";
    };
    TokenFormStore.prototype.count = function () {
        return Object.values(this.formFields).length;
    };
    TokenFormStore.prototype.setById = function (id, value) {
        this.formFields[id] = value;
    };
    var _a;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", typeof (_a = typeof kaleidoscope_1.IFormValues !== "undefined" && kaleidoscope_1.IFormValues) === "function" ? _a : Object)
    ], TokenFormStore.prototype, "formFields", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", void 0)
    ], TokenFormStore.prototype, "setById", null);
    return TokenFormStore;
}());
exports.default = TokenFormStore;
