"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Types_1 = require("@CommonFrontendBackend/Templates/Types");
var Types_2 = require("@CommonFrontendBackend/Projects/Types");
var types_1 = require("./types");
var Types_3 = require("@CommonFrontendBackend/Account/Integrations/Types");
var Types_4 = require("@CommonFrontend/Project/Types");
var FlowStore = (function () {
    function FlowStore(_a) {
        var apiService = _a.apiService, prompts = _a.prompts, templateId = _a.templateId, integrationName = _a.integrationName, objectType = _a.objectType, objectId = _a.objectId, tokenList = _a.tokenList, projectTour = _a.projectTour, skipFolderSelection = _a.skipFolderSelection;
        this.objectURL = "";
        this.folderId = null;
        this.apiService = apiService;
        this.prompts = prompts;
        this.templateId = templateId;
        this.integrationName = integrationName;
        this.objectType = objectType;
        this.objectId = objectId;
        this.tokenList = tokenList;
        this.projectTour = projectTour;
        this.skipFolderSelection = skipFolderSelection;
    }
    FlowStore.prototype.hidePageStateLivePrompt = function () {
        if (this.prompts) {
            this.prompts.pageStateLive.show = false;
            this.prompts.pageStateLive.onHide();
        }
        else {
            throw new Error("prompts object does not exist");
        }
    };
    FlowStore.prototype.setTemplateId = function (templateId) {
        this.templateId = templateId;
    };
    FlowStore.prototype.setTemplate = function (template) {
        this.template = template;
    };
    FlowStore.prototype.setTemplateList = function (templateList) {
        this.templateList = templateList;
    };
    FlowStore.prototype.setIntegrationName = function (integrationName) {
        this.integrationName = integrationName;
    };
    FlowStore.prototype.setIntegrationState = function (integrationState) {
        this.integrationState = integrationState;
    };
    FlowStore.prototype.setObjectType = function (objectType) {
        this.objectType = objectType;
    };
    FlowStore.prototype.setObjectId = function (objectId) {
        this.objectId = objectId;
    };
    FlowStore.prototype.setObjectURL = function (objectURL) {
        this.objectURL = objectURL;
    };
    FlowStore.prototype.setResolvedTokens = function (resolvedTokens) {
        this.resolvedTokens = resolvedTokens;
    };
    FlowStore.prototype.setCreatingPageError = function (creatingPageError) {
        this.creatingPageError = creatingPageError;
    };
    FlowStore.prototype.setPageTitle = function (pageTitle) {
        this.pageTitle = pageTitle;
    };
    FlowStore.prototype.setFolderId = function (folderId) {
        this.folderId = folderId;
    };
    FlowStore.prototype.setSubstitutions = function (substitutions) {
        this.substitutions = substitutions;
    };
    FlowStore.prototype.setCreatedPage = function (createdPage) {
        this.createdPage = createdPage;
    };
    FlowStore.prototype.setCreatedPageStatus = function (pageStatusState) {
        return __awaiter(this, void 0, Promise, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.createdPage) return [3, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.apiService.setStatus(this.createdPage._id, pageStatusState)];
                    case 2:
                        _a.sent();
                        this.createdPage.status = pageStatusState.status;
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [2, Promise.reject(e_1)];
                    case 4: return [2];
                }
            });
        });
    };
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", typeof (_a = typeof types_1.Prompts !== "undefined" && types_1.Prompts) === "function" ? _a : Object)
    ], FlowStore.prototype, "prompts", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], FlowStore.prototype, "templateId", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], FlowStore.prototype, "integrationName", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_b = typeof Types_3.TokenIntegrationVerificationResult !== "undefined" && Types_3.TokenIntegrationVerificationResult) === "function" ? _b : Object)
    ], FlowStore.prototype, "integrationState", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], FlowStore.prototype, "objectType", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], FlowStore.prototype, "objectId", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], FlowStore.prototype, "objectURL", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_c = typeof Types_1.TemplateProject !== "undefined" && Types_1.TemplateProject) === "function" ? _c : Object)
    ], FlowStore.prototype, "template", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Array)
    ], FlowStore.prototype, "templateList", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Array)
    ], FlowStore.prototype, "tokenList", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_d = typeof Record !== "undefined" && Record) === "function" ? _d : Object)
    ], FlowStore.prototype, "tokenValues", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_e = typeof Types_1.TmsResolutionResponse !== "undefined" && Types_1.TmsResolutionResponse) === "function" ? _e : Object)
    ], FlowStore.prototype, "resolvedTokens", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], FlowStore.prototype, "creatingPageError", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], FlowStore.prototype, "pageTitle", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], FlowStore.prototype, "folderId", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_f = typeof Record !== "undefined" && Record) === "function" ? _f : Object)
    ], FlowStore.prototype, "substitutions", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_g = typeof Types_2.PageCreated !== "undefined" && Types_2.PageCreated) === "function" ? _g : Object)
    ], FlowStore.prototype, "createdPage", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_h = typeof Types_4.ProjectTourType !== "undefined" && Types_4.ProjectTourType) === "function" ? _h : Object)
    ], FlowStore.prototype, "projectTour", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Boolean)
    ], FlowStore.prototype, "skipFolderSelection", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "hidePageStateLivePrompt", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setTemplateId", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_j = typeof Types_1.TemplateProject !== "undefined" && Types_1.TemplateProject) === "function" ? _j : Object]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setTemplate", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setTemplateList", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setIntegrationName", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_k = typeof Types_3.TokenIntegrationVerificationResult !== "undefined" && Types_3.TokenIntegrationVerificationResult) === "function" ? _k : Object]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setIntegrationState", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setObjectType", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setObjectId", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setObjectURL", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_l = typeof Types_1.TmsResolutionResponse !== "undefined" && Types_1.TmsResolutionResponse) === "function" ? _l : Object]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setResolvedTokens", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setCreatingPageError", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setPageTitle", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setFolderId", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_m = typeof Record !== "undefined" && Record) === "function" ? _m : Object]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setSubstitutions", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_o = typeof Types_2.PageCreated !== "undefined" && Types_2.PageCreated) === "function" ? _o : Object]),
        __metadata("design:returntype", void 0)
    ], FlowStore.prototype, "setCreatedPage", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_p = typeof Types_2.PageStatusState !== "undefined" && Types_2.PageStatusState) === "function" ? _p : Object]),
        __metadata("design:returntype", typeof (_q = typeof Promise !== "undefined" && Promise) === "function" ? _q : Object)
    ], FlowStore.prototype, "setCreatedPageStatus", null);
    return FlowStore;
}());
exports.default = FlowStore;
