"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromptId = void 0;
var PromptId;
(function (PromptId) {
    PromptId["EditText"] = "editText";
    PromptId["Styles"] = "styles";
    PromptId["AddBlock"] = "addBlock";
    PromptId["Share"] = "share";
    PromptId["Comments"] = "comments";
    PromptId["ContentLibrary"] = "contentLibrary";
    PromptId["CollaboratorLink"] = "collaboratorLink";
    PromptId["AutoLinkExpirySettings"] = "autoLinkExpirySettings";
    PromptId["PagesDashboardOnboardingBanner"] = "pagesDashboardOnboardingBanner";
    PromptId["DiscoverSidebar"] = "discoverSidebar";
    PromptId["ArchivedTemplatesExplanation"] = "archivedTemplatesExplanation";
    PromptId["TourWelcome"] = "tourWelcome";
    PromptId["TemplatesWelcome"] = "templatesWelcome";
    PromptId["IntegrationsHubspotOnboarding"] = "integrationsHubspotOnboarding";
    PromptId["ArchivedPagesExplanation"] = "archivedPagesExplanation";
    PromptId["AnalyticsReportUpsell"] = "analyticsReportUpsell";
    PromptId["PipelineReportOnboarding"] = "pipelineReportOnboarding";
    PromptId["EngagementLibraryOnboarding"] = "engagementLibraryOnboarding";
    PromptId["EnableAutomationExplanation"] = "enableAutomationExplanation";
    PromptId["AutomationsOverview"] = "automationsOverview";
    PromptId["AnalyticsTimeZone"] = "analyticsTimeZone";
    PromptId["TailoredPageAddBlock"] = "tailoredPageAddBlock";
    PromptId["TailoredPageSelectContent"] = "tailoredPageSelectContent";
    PromptId["TailoredPageCollaborate"] = "tailoredPageCollaborate";
    PromptId["AnalyticsMultiBlockEnteredScreen"] = "analyticsMultiBlockEnteredScreen";
    PromptId["AddAgreement"] = "addAgreement";
    PromptId["ContentSidebarExploreTab"] = "contentSidebarExploreTab";
})(PromptId || (exports.PromptId = PromptId = {}));
