"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPlatformSupportedIntegration = exports.getIntegrationObjectUrlPlaceholder = exports.extractIntegrationObjectIdFromUrl = exports.getSingularObjectName = exports.startsWithVowel = exports.isInvalidObjectUrlError = exports.InvalidObjectUrlError = exports.GENERIC_ERROR = void 0;
var lodash_1 = require("lodash");
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var INTEGRATIONS = {
    ZOHO: "zoho",
    PIPEDRIVE: "pipedrive",
};
exports.GENERIC_ERROR = "Something went wrong. Please refresh and try again. If the problem persists, contact support.";
var trimSlash = function (value) {
    return value.replace(/\/+$/, "");
};
var InvalidObjectUrlError = (function (_super) {
    __extends(InvalidObjectUrlError, _super);
    function InvalidObjectUrlError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, InvalidObjectUrlError.prototype);
        return _this;
    }
    return InvalidObjectUrlError;
}(Error));
exports.InvalidObjectUrlError = InvalidObjectUrlError;
var isInvalidObjectUrlError = function (e) {
    return e instanceof InvalidObjectUrlError;
};
exports.isInvalidObjectUrlError = isInvalidObjectUrlError;
var startsWithVowel = function (word) {
    return "AEIOUaeiou".indexOf(word.slice(0, 1)) > -1;
};
exports.startsWithVowel = startsWithVowel;
var getSingularObjectName = function (_a, _b) {
    var object = _a.object, integration = _a.integration;
    var _c = _b === void 0 ? { prefix: false } : _b, prefix = _c.prefix;
    if (integration === INTEGRATIONS.ZOHO || integration === INTEGRATIONS.PIPEDRIVE) {
        var singularObjectName = object.slice(0, -1);
        if (prefix) {
            return "a".concat((0, exports.startsWithVowel)(singularObjectName) ? "n" : "", " ").concat(singularObjectName);
        }
        return singularObjectName;
    }
    return object;
};
exports.getSingularObjectName = getSingularObjectName;
var extractIntegrationObjectIdFromUrl = function (value, integration, object) {
    var URL_FORMAT_ERROR = "We're unable to retrieve the ".concat((0, exports.getSingularObjectName)({
        object: object,
        integration: integration,
    }), " from the provided URL. Please double-check the URL and try again.");
    var OBJECT_MISMATCH_ERROR = "The URL doesn't look to be for ".concat((0, exports.getSingularObjectName)({ object: object, integration: integration }, { prefix: true }), ". Please paste the URL from your CRM without any changes.");
    var url;
    try {
        url = new URL(value);
    }
    catch (e) {
        throw new InvalidObjectUrlError(URL_FORMAT_ERROR);
    }
    var paths = trimSlash(url.pathname).split("/");
    var objectInUrl;
    switch (integration) {
        case INTEGRATIONS.ZOHO:
            objectInUrl = paths[paths.length - 2];
            if (!objectInUrl) {
                throw new InvalidObjectUrlError(URL_FORMAT_ERROR);
            }
            if (object === "Deals") {
                if (objectInUrl !== "Deals" && objectInUrl !== "Potentials") {
                    throw new InvalidObjectUrlError(OBJECT_MISMATCH_ERROR);
                }
            }
            else if (objectInUrl !== object) {
                throw new InvalidObjectUrlError(OBJECT_MISMATCH_ERROR);
            }
            return paths[paths.length - 1];
        case INTEGRATIONS.PIPEDRIVE:
            objectInUrl = paths[1];
            if (!objectInUrl) {
                throw new InvalidObjectUrlError(URL_FORMAT_ERROR);
            }
            if ("Leads" !== object) {
                object = (0, exports.getSingularObjectName)({ object: object, integration: integration });
            }
            if (objectInUrl !== (0, lodash_1.lowerCase)(object)) {
                throw new InvalidObjectUrlError(OBJECT_MISMATCH_ERROR);
            }
            return paths[paths.length - 1];
        default:
            throw new Error("Not supported");
    }
};
exports.extractIntegrationObjectIdFromUrl = extractIntegrationObjectIdFromUrl;
var getIntegrationObjectUrlPlaceholder = function (integration, object) {
    switch (integration) {
        case INTEGRATIONS.ZOHO:
            return "e.g. https://zoho.com/crm/".concat(object, "/123456");
        case INTEGRATIONS.PIPEDRIVE:
            return "e.g. https://username.pipedrive.com/".concat(object, "/123");
        default:
            return "e.g. https://example.com/object/123";
    }
};
exports.getIntegrationObjectUrlPlaceholder = getIntegrationObjectUrlPlaceholder;
var isPlatformSupportedIntegration = function (integrationName) {
    return Types_1.PLATFORM_SUPPORTED_PROVIDER_TYPES.has(integrationName);
};
exports.isPlatformSupportedIntegration = isPlatformSupportedIntegration;
