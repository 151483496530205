"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBrandOrSnapshotColor = void 0;
var ColorUtils_1 = require("./ColorUtils");
var getBrandOrSnapshotColor = function (color, brandColors) {
    var themeIndex = color.themeIndex, abs = color.abs;
    var themeColor = themeIndex != null ? brandColors[(themeIndex + brandColors.length) % brandColors.length] : null;
    if (themeIndex != null && themeColor) {
        return themeColor;
    }
    return sanitiseAbsColor(abs);
};
exports.getBrandOrSnapshotColor = getBrandOrSnapshotColor;
var sanitiseAbsColor = function (color) {
    var sanitisedColor = color;
    if ((0, ColorUtils_1.isValidHex)(color) && color.charAt(0) !== "#") {
        sanitisedColor = "#".concat(color);
    }
    return sanitisedColor;
};
