"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAtLeast = exports.pickGreaterAction = exports.isGreater = exports.minAction = exports.maxAction = exports.getAdminUserRole = exports.isAdmin = exports.hasRole = void 0;
var _ = require("lodash");
var Types_1 = require("./Types");
function hasRole(user, role) {
    var userRoles = user.roles;
    if (!_.isNil(userRoles)) {
        var v = _.find(userRoles, { name: role });
        return !_.isNil(v);
    }
    else {
        return false;
    }
}
exports.hasRole = hasRole;
function isAdmin(user) {
    return hasRole(user, Types_1.Role.Admin);
}
exports.isAdmin = isAdmin;
function getAdminUserRole() {
    return { name: Types_1.Role.Admin };
}
exports.getAdminUserRole = getAdminUserRole;
function maxAction(actions) {
    var sortedActions = actions.filter(notUndefinedOrNull).sort(function (action1, action2) {
        return Types_1.OrderedAccessLevels.indexOf(action1) - Types_1.OrderedAccessLevels.indexOf(action2);
    });
    if (sortedActions.length === 0) {
        return Types_1.Action.None;
    }
    return sortedActions[0];
}
exports.maxAction = maxAction;
function minAction(actions) {
    var sortedActions = actions.filter(notUndefinedOrNull).sort(function (action1, action2) {
        return (Types_1.OrderedAccessLevels.indexOf(action1) - Types_1.OrderedAccessLevels.indexOf(action2)) * -1;
    });
    if (sortedActions.length === 0) {
        return Types_1.Action.None;
    }
    return sortedActions[0];
}
exports.minAction = minAction;
var isGreater = function (action1, action2) {
    return getActionRank(action2) - getActionRank(action1) > 0;
};
exports.isGreater = isGreater;
var pickGreaterAction = function (action1, action2) {
    if (action2 == undefined) {
        return action1;
    }
    else if ((0, exports.isGreater)(action1, action2)) {
        return action1;
    }
    else {
        return action2;
    }
};
exports.pickGreaterAction = pickGreaterAction;
var isAtLeast = function (action1, action2) {
    return getActionRank(action2) - getActionRank(action1) >= 0;
};
exports.isAtLeast = isAtLeast;
var getActionRank = function (action) {
    if (action) {
        return Types_1.OrderedAccessLevels.indexOf((0, Types_1.getActionFromString)(action));
    }
    else {
        return Types_1.OrderedAccessLevels.indexOf(Types_1.Action.None);
    }
};
var notUndefinedOrNull = function (v) { return !(v === undefined || v === null); };
