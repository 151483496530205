// Generated by CoffeeScript 1.12.7

/*
  Get Font Size

  @param fontStyles [Object] the font style as defined on the style object
  @param element [String] one of "h1", "h2", "p", "li"
  @param displayType [String] (Optional) one of "desktop", "mobile", "print"
 */

(function () {
  var addPt,
    addPx,
    elementToSizeMap,
    formatNumber,
    getAsTwoDecimalPlace,
    getHeadingSize,
    getParagraphSize,
    getSubheadingSize;

  getHeadingSize = function (scale, ratio, displayType) {
    if (displayType === "desktop") {
      return 1.05 * Math.pow(ratio, 3) * getParagraphSize(scale, ratio, displayType);
    } else if (displayType === "mobile") {
      return 1.05 * Math.pow(ratio, 1.954) * getParagraphSize(scale, ratio, displayType);
    } else if (displayType === "print") {
      return 1.05 * Math.pow(ratio, 3.03) * getParagraphSize(scale, ratio, displayType);
    }
  };

  getSubheadingSize = function (scale, ratio, displayType) {
    if (displayType === "desktop") {
      return Math.pow(ratio, 1.65) * getParagraphSize(scale, ratio, displayType);
    } else if (displayType === "mobile") {
      return Math.pow(ratio, 1.44) * getParagraphSize(scale, ratio, displayType);
    } else if (displayType === "print") {
      return Math.pow(ratio, 1.305) * getParagraphSize(scale, ratio, displayType);
    }
  };

  getParagraphSize = function (scale, ratio, displayType) {
    if (displayType === "desktop") {
      return scale;
    } else if (displayType === "mobile") {
      return (scale * 15) / 18;
    } else if (displayType === "print") {
      return (scale * 8) / 18;
    }
  };

  addPx = function (num) {
    return num + "px";
  };

  addPt = function (num) {
    return num + "pt";
  };

  getAsTwoDecimalPlace = function (value) {
    return value.toFixed(2);
  };

  formatNumber = function (value, displayType) {
    if (displayType === "print") {
      return addPt(getAsTwoDecimalPlace(value));
    } else {
      return addPx(getAsTwoDecimalPlace(value));
    }
  };

  elementToSizeMap = {
    h1: getHeadingSize,
    h2: getSubheadingSize,
    p: getParagraphSize,
  };

  module.exports = function (fontStyles, element, displayType) {
    var ratio, ref, scale;
    if (displayType == null) {
      displayType = "desktop";
    }
    if (element === "li") {
      element = "p";
    }
    if (_.isEmpty(fontStyles[element])) {
      (scale = fontStyles.scale), (ratio = fontStyles.ratio);
    } else {
      (ref = fontStyles[element]), (scale = ref.scale), (ratio = ref.ratio);
    }
    return formatNumber(elementToSizeMap[element](scale, ratio, displayType), displayType);
  };
}.call(this));
