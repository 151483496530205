"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.link = void 0;
var Common_1 = require("ContentTokens/Models/Common");
exports.link = {
    createToken: function (content, styles, href) {
        throw new Error("Not implemented");
    },
    createSlateJson: function (children, href) {
        var linkJson = {
            type: Common_1.InlineType.Link,
            data: {
                href: href,
            },
            children: children,
        };
        return linkJson;
    },
};
