"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSignupEntryPoints = void 0;
var UserSignupEntryPoints;
(function (UserSignupEntryPoints) {
    UserSignupEntryPoints["Standard"] = "standard";
    UserSignupEntryPoints["Template"] = "template";
    UserSignupEntryPoints["PageBuilderAI"] = "page_builder_ai";
    UserSignupEntryPoints["Dpg"] = "dpg";
    UserSignupEntryPoints["AdminPanelDocConversion"] = "adminPanelDocConversion";
})(UserSignupEntryPoints || (exports.UserSignupEntryPoints = UserSignupEntryPoints = {}));
