"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTelemetryOrigin = exports.PagesWithFilter = void 0;
var PagesWithFilter;
(function (PagesWithFilter) {
    PagesWithFilter["AcceptBlock"] = "accept block";
    PagesWithFilter["QuoteBlock"] = "quote block";
    PagesWithFilter["Value"] = "value";
})(PagesWithFilter || (exports.PagesWithFilter = PagesWithFilter = {}));
var EmailTelemetryOrigin;
(function (EmailTelemetryOrigin) {
    EmailTelemetryOrigin["Engagement"] = "engagementNotificationEmail";
    EmailTelemetryOrigin["Notification"] = "notificationEmail";
})(EmailTelemetryOrigin || (exports.EmailTelemetryOrigin = EmailTelemetryOrigin = {}));
