"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.safeDivide = void 0;
function safeDivide(numerator, denominator, precision) {
    if (precision === void 0) { precision = 0; }
    if (denominator === 0)
        return 0;
    return Number((numerator / denominator).toFixed(precision));
}
exports.safeDivide = safeDivide;
