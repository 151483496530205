// Generated by CoffeeScript 1.12.7
(function () {
  var Contract, ContractSingleton, _, interfaceJs;

  _ = require("lodash");

  interfaceJs = require("interface-js");

  /*
    A lightweight wrapper around the ContractSingleton, to ensure we can never accidentally create
    more than one ContractSingleton (and hence override `interfaceJs.bsonId` more than once)
  
    We can accidentally (or deliberately, in the case of our tests) instantiate as many of these
    Contract objects as we want without breaking the singleton pattern
  
    @NOTE (som 2017-09-12): in our tests, Contract is instantiated first by UnitTestHeader, and
    then by the server Wiring. This is why this singleton structure allows us to have multiple
    Contract() objects all referring to a singleton instance
  
    IMPORTANT: this structure still relies on us passing in the correct `utils` the first time a
    Contract is instantiated!
   */

  module.exports = Contract = (function () {
    function Contract(utils) {
      if (ContractSingleton.instance == null) {
        new ContractSingleton(utils);
      }
      this.make = function (interfaceModel) {
        return ContractSingleton.instance.make(interfaceModel);
      };
      this.check = function (interfaceObj, data) {
        return ContractSingleton.instance.check(interfaceObj, data);
      };
    }

    return Contract;
  })();

  /*
    A wrapper around interface-js
  
    This class has potential side effects as it modifies interface-js. This is why we
    enforce the singleton pattern as strictly as possible
   */

  ContractSingleton = (function () {
    ContractSingleton.instance = null;

    function ContractSingleton(utils) {
      if (ContractSingleton.instance != null) {
        throw new Error("Tried to instantiate Contract singleton more than once!");
      }
      if (utils == null) {
        throw new Error("Tried to instantiate Contract without utils");
      }
      ContractSingleton.instance = this;
      interfaceJs.bsonId = {
        name: "bsonId",
        satisfied: function (val) {
          return utils.isBsonId(val);
        },
        check: interfaceJs.check,
      };

      /*
        A function to check whether an object's properties conform to a given type specification
       */
      this.make = (function (_this) {
        return function (interfaceModel) {
          var contract, extractedContract, fieldName, includedRegex, includedType, optional, typeName;
          contract = {};
          includedRegex = /\((\w+)\)/;
          for (fieldName in interfaceModel) {
            typeName = interfaceModel[fieldName];
            if (_.isString(typeName)) {
              optional = false;
              if (_.includes(typeName, "|optional")) {
                typeName = typeName.replace("|optional", "");
                optional = true;
              }
              if (_.includes(typeName, "list")) {
                if ((includedType = typeName.match(includedRegex))) {
                  extractedContract = interfaceJs["list"](interfaceJs[includedType[1]]);
                }
              } else if (_.includes(typeName, "map")) {
                if ((includedType = typeName.match(includedRegex))) {
                  extractedContract = interfaceJs["map"](interfaceJs[includedType[1]]);
                }
              } else {
                extractedContract = interfaceJs[typeName];
              }
              contract[fieldName] = optional ? interfaceJs.optional(extractedContract) : extractedContract;
            } else if (_.keys(typeName).length) {
              contract[fieldName] = _this.make(interfaceModel[fieldName]);
            }
          }
          return interfaceJs(contract);
        };
      })(this);
      this.check = function (interfaceObj, data) {
        var e;
        try {
          interfaceObj.check(data);
          return true;
        } catch (error) {
          e = error;
          return false;
        }
      };
    }

    return ContractSingleton;
  })();
}.call(this));
