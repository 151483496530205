// This file was modified from the original Coffeescript source to:
// - add strikethrough support: https://github.com/qwilr/qwilr/pull/6893
(function () {
  var assert,
    fn,
    inlineAttributes,
    isBlock,
    kebabToCamel,
    name,
    simpleStyleAttributes,
    whitelist,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  simpleStyleAttributes = {
    "font-weight": ["bold", "500", "600", "700", "800"],
    "font-style": ["italic"],
    "text-decoration-line": ["line-through"],
  };

  inlineAttributes = {
    link: {
      read: function (elem, computedStyle) {
        return elem.href || null;
      },
      write: function (elem, value) {
        return (elem.href = value);
      },
    },
  };

  fn = function (name, whitelist) {
    return (inlineAttributes[name] = {
      read: function (elem, computedStyle) {
        var val;
        val = computedStyle[name] || computedStyle[kebabToCamel(name)];
        if (indexOf.call(whitelist, val) >= 0) {
          return val;
        } else {
          return null;
        }
      },
      write: function (elem, value) {
        if (indexOf.call(whitelist, value) >= 0) {
          return (elem.style[name] = value);
        }
      },
    });
  };
  for (name in simpleStyleAttributes) {
    whitelist = simpleStyleAttributes[name];
    fn(name, whitelist);
  }

  isBlock = function (node) {
    return node.nodeType !== 3 && qed.util.isBlock(node);
  };

  assert = function (truth, msg) {
    if (!truth) {
      msg || (msg = "assertion failure");
      throw new Error(msg);
    }
  };

  kebabToCamel = function (s) {
    return s.replace(/(\-\w)/g, function (m) {
      return m[1].toUpperCase();
    });
  };

  module.exports = {
    inlineAttributes: inlineAttributes,
    isBlock: isBlock,
    assert: assert,
  };
}.call(this));
