"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmailDomain = void 0;
exports.default = (function (email) {
    if (!email) {
        return false;
    }
    var domain = (0, exports.getEmailDomain)(email);
    return !!domain && personalEmailDomains.includes(domain);
});
var personalEmailDomains = [
    "gmail.com",
    "googlemail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "aim.com",
    "yandex.com",
    "protonmail.com",
    "protonmail.ch",
    "pm.me",
    "zoho.com",
    "mail.com",
    "email.com",
    "icloud.com",
    "test.com",
    "testing.com",
    "bugcrowdninja.com",
    "guerrillamail.com",
    "maildrop.cc",
    "wearehackerone.com",
];
var getEmailDomain = function (email) {
    var emailSplit = email.toLowerCase().split("@");
    if (emailSplit.length === 2) {
        return emailSplit[1];
    }
    return null;
};
exports.getEmailDomain = getEmailDomain;
