// Generated by CoffeeScript 1.12.7
(function () {
  var Point, assert, cleanupPoint, extractSingleMarker, findEndpoints, removeChar, textPoint, util;

  assert = require("chai").assert;

  Point = qed.Point;

  util = qed.util;

  module.exports = function (elem) {
    var junkNodes, result;
    result = {};
    findEndpoints(elem, result);
    if (!result.start || !result.end) {
      throw new Error("No range found in " + elem.innerHTML);
    }
    junkNodes = [];
    result.start = cleanupPoint(result.start, junkNodes);
    result.end = cleanupPoint(result.end, junkNodes);
    junkNodes.forEach(util.removeNode);
    return result;
  };

  findEndpoints = function (elem, result) {
    var node, temp;
    node = elem.firstChild;
    while (node != null) {
      if (node.nodeType === 1) {
        findEndpoints(node, result);
      } else {
        temp = extractSingleMarker(node, result);
        while (temp) {
          temp = extractSingleMarker(node, result);
        }
      }
      node = node.nextSibling;
    }
    return result;
  };

  extractSingleMarker = function (node, result) {
    var index, txt;
    txt = node.data;
    index = txt.indexOf("|");
    if (index >= 0) {
      assert(!result.start && !result.end);
      removeChar(node, index);
      result.start = Point.text(node, index);
      result.end = result.start.copy();
      return true;
    }
    index = txt.indexOf("[");
    if (index >= 0) {
      assert(!result.start && !result.end);
      removeChar(node, index);
      result.start = Point.text(node, index);
      return true;
    }
    index = txt.indexOf("]");
    if (index >= 0) {
      assert(result.start && !result.end);
      removeChar(node, index);
      result.end = Point.text(node, index);
      return true;
    }
    return false;
  };

  cleanupPoint = function (point, junkNodes) {
    var node;
    if (point.isInEmptyTextNode()) {
      node = point.node;
      point = Point.before(node).leftNormalized();
      junkNodes.push(node);
    }
    return point;
  };

  textPoint = function (textNode, offset) {
    var point;
    if (textNode.length === 0) {
      assert(offset === 0);
      point = Point.before(textNode).leftNormalized();
      util.removeNode(textNode);
      return point;
    } else {
      return Point.text(textNode, offset);
    }
  };

  removeChar = function (textNode, index) {
    var data;
    data = textNode.data;
    assert(index >= 0 && index < data.length);
    return (textNode.data = data.substr(0, index) + data.substr(index + 1));
  };
}.call(this));
