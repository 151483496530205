// Generated by CoffeeScript 1.12.7

/*
  This module abstracts some common functionality
  and information for handling keypresses and key codes
  in a more human readable way.
 */

(function () {
  var Keyboard;

  module.exports = Keyboard = (function () {
    function Keyboard($log) {
      /*
        Determine if a given key event corresponds
        to a given key name (i.e. "ENTER" or "TAB")
        @param e [Event] - the key event
        @param keyName [String] - the name of the key to match against.
       */
      this.isKey = function (event, keyName) {
        if (this.keys[keyName] == null) {
          $log.error("Looking for an unknown key name: " + keyName);
        }
        return this.getKeycode(event) === this.keys[keyName];
      };

      /*
        Get the key code for a given key event.
        @param e [Event] - the key event.
       */
      this.getKeycode = function (event) {
        return event.charCode || event.keyCode;
      };
      this.keys = {
        ENTER: 13,
        SPACE: 32,
        TAB: 9,
        BACKSPACE: 8,
        LEFT: 37,
        RIGHT: 39,
        DOWN: 40,
        UP: 38,
        ALT: 18,
        EQUALS: 187,
      };
    }

    return Keyboard;
  })();
}.call(this));
