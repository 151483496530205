"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = exports.isColorMark = exports.MarkType = void 0;
var slate_1 = require("slate");
var MarkType_1 = require("./MarkType");
Object.defineProperty(exports, "MarkType", { enumerable: true, get: function () { return MarkType_1.MarkType; } });
var isColorMark = function (mark) { return mark.type === MarkType_1.MarkType.Color; };
exports.isColorMark = isColorMark;
exports.Text = {
    isText: function (node) {
        return slate_1.Text.isText(node);
    },
};
