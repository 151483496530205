"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_VARIABLE_INPUT_KEY = exports.getNumberFromKey = exports.getKeyFromNumber = void 0;
var getKeyFromNumber = function (number) {
    var key = "".concat(getAlphaPositionFromNumber(number % 26 || 26)).concat(Math.floor((number - 1) / 26) || "");
    return key;
};
exports.getKeyFromNumber = getKeyFromNumber;
var getNumberFromKey = function (key) {
    if (key.length === 1) {
        return getAlphaPosition(key);
    }
    var _a = __read(key, 2), char1 = _a[0], char2 = _a[1];
    return getAlphaPosition(char1) + 26 * parseInt(char2, 10);
};
exports.getNumberFromKey = getNumberFromKey;
var getAlphaPosition = function (character) {
    return parseInt(character, 36) - 9;
};
var getAlphaPositionFromNumber = function (value) {
    return String.fromCharCode(64 + value);
};
exports.MAX_VARIABLE_INPUT_KEY = 260;
