"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.folderAsResource = exports.DashboardResourceType = exports.SortField = exports.SortDirection = void 0;
var SortDirection;
(function (SortDirection) {
    SortDirection[SortDirection["Ascending"] = 1] = "Ascending";
    SortDirection[SortDirection["Descending"] = -1] = "Descending";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
var SortField;
(function (SortField) {
    SortField["Name"] = "name";
    SortField["DateCreated"] = "dateCreated";
    SortField["LastEdited"] = "lastEditedAt";
    SortField["Views"] = "views";
    SortField["LastViewedAt"] = "lastViewedAt";
})(SortField || (exports.SortField = SortField = {}));
var DashboardResourceType;
(function (DashboardResourceType) {
    DashboardResourceType["Folder"] = "folder";
    DashboardResourceType["Page"] = "page";
    DashboardResourceType["SavedBlock"] = "savedBlock";
    DashboardResourceType["UploadedImage"] = "uploadedImage";
    DashboardResourceType["Template"] = "template";
})(DashboardResourceType || (exports.DashboardResourceType = DashboardResourceType = {}));
function folderAsResource(folderId) {
    return {
        id: folderId,
        type: DashboardResourceType.Folder,
    };
}
exports.folderAsResource = folderAsResource;
