"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.darken = exports.lighten = exports.ColorPalette = void 0;
var ColorPalette;
(function (ColorPalette) {
    ColorPalette["Emerald"] = "#00857b";
    ColorPalette["Blue"] = "#1099FC";
    ColorPalette["Gold"] = "#FFCE53";
    ColorPalette["Purple"] = "#716BF1";
    ColorPalette["Grey"] = "#81A2B2";
    ColorPalette["Green"] = "#0BDAC9";
    ColorPalette["Blueish"] = "#113c51";
    ColorPalette["Midnight"] = "#242C39";
    ColorPalette["Dark"] = "#242C39";
    ColorPalette["Slate"] = "#47535D";
    ColorPalette["Orange"] = "#FFB961";
    ColorPalette["Red"] = "#D71939";
    ColorPalette["RedLight"] = "#FF6161";
    ColorPalette["Silver"] = "#F2F5F7";
})(ColorPalette || (exports.ColorPalette = ColorPalette = {}));
var lighten = function (hexColor, percentage) { return newShade(hexColor, percentage); };
exports.lighten = lighten;
var darken = function (hexColor, percentage) { return newShade(hexColor, -percentage); };
exports.darken = darken;
var newShade = function (hexColor, magnitude) {
    hexColor = hexColor.replace("#", "");
    if (hexColor.length === 6) {
        var decimalColor = parseInt(hexColor, 16);
        var r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        var g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        var b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return "#".concat((g | (b << 8) | (r << 16)).toString(16));
    }
    else {
        return hexColor;
    }
};
