"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.upgradeTokens = void 0;
var CommonWidgetRegistry_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgetRegistry");
var BlockTokenTraverser_1 = require("@CommonFrontendBackend/Blocks/BlockTokenTraverser");
var upgradeTokens = function (tokens, _a) {
    var usingFeature = _a.usingFeature;
    var tokenTraverser = new BlockTokenTraverser_1.BlockTokenTraverser();
    return tokenTraverser.upgradeTokens(tokens, function (token) {
        var e_1, _a;
        var upgradedToken;
        try {
            for (var CommonWidgetRegistryConfigs_1 = __values(CommonWidgetRegistry_1.CommonWidgetRegistryConfigs), CommonWidgetRegistryConfigs_1_1 = CommonWidgetRegistryConfigs_1.next(); !CommonWidgetRegistryConfigs_1_1.done; CommonWidgetRegistryConfigs_1_1 = CommonWidgetRegistryConfigs_1.next()) {
                var config = CommonWidgetRegistryConfigs_1_1.value;
                if ("tokenUpgradeConverter" in config) {
                    if (token.type !== "block" && token.type !== "inline")
                        continue;
                    upgradedToken = config.tokenUpgradeConverter(token, { usingFeature: usingFeature });
                    if (!upgradedToken)
                        continue;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (CommonWidgetRegistryConfigs_1_1 && !CommonWidgetRegistryConfigs_1_1.done && (_a = CommonWidgetRegistryConfigs_1.return)) _a.call(CommonWidgetRegistryConfigs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return upgradedToken || token;
    });
};
exports.upgradeTokens = upgradeTokens;
