// Generated by CoffeeScript 1.12.7
(function () {
  var navigationModel;

  navigationModel = require("./Navigation");

  module.exports = {
    name: "",
    type: "Source",
    cssBlockName: "source-module",
    url: "",
    sourceType: "",
    settings: {
      navigation: navigationModel,
      size: "three-quarters",
    },
  };
}.call(this));
