// Generated by CoffeeScript 1.12.7

/*
  The module used to create MultiSelectDropdowns.
  Selecting via checkboxes
  bells, or whistles to worry about.

  If there are more than 10 elements, there is an added field for search.
 */

(function () {
  var MultiSelectDropdownFactory;

  module.exports = MultiSelectDropdownFactory = (function () {
    function MultiSelectDropdownFactory(createUi, OnClickOutside, make, noop, Search) {
      /*
       * Creates a MultiSelectDropdown UI element
       * @param items [Array] An array of items in the list
       * @param onSelect [Function] What to do when something is selected/deselected. Function takes
       * the recently selected and an array of all the items currently selected
       * @param options [Object] Options for the dropdown:
       *   - theme [String] What style 'theme' to apply to the dropdown
       *   - title [String] What should be displayed in the main dropdown box
       *   - updateTitleOnSelection [Boolean] Whether the title should change as items are selected
       *     (showing what has been selected in the title)
       *   - multipleSelectedTitle [String] What should be displayed when multiple options are selected
       *   - icon [String] Location of an icon to place in the dropdown
       *   - useDeselectAllButton [Boolean] Show an 'X' when there are items selected to allow them to
       *     be deselected at once. Not compatible with the `icon` option
       *   - searchFn [Function] A custom 'search' function. Takes an item and the search term and
       *     returns a boolean whether the item matches the search
       */
      this.create = (function (_this) {
        return function (items, onSelect, options) {
          var onClickOutside, searchTerm, ui;
          if (onSelect == null) {
            onSelect = noop;
          }
          if (options == null) {
            options = {};
          }
          if (options.title == null) {
            options.title = "Click to Select";
          }
          if (options.multipleSelectedTitle == null) {
            options.multipleSelectedTitle = "Multiple selections";
          }
          if (options.theme == null) {
            options.theme = "normal";
          }
          if (options.updateTitleOnSelection == null) {
            options.updateTitleOnSelection = false;
          }
          if (options.useDeselectAllButton == null) {
            options.useDeselectAllButton = options.icon != null;
          }
          onClickOutside = null;
          searchTerm = null;
          return (ui = createUi(require("./MultiSelectDropdown.html"), {
            getTitle: function () {
              if (!options.updateTitleOnSelection) {
                return options.title;
              } else {
                switch (ui.allSelected.length) {
                  case 0:
                    return options.title;
                  case 1:
                    return ui.allSelected[0].name;
                  default:
                    return options.multipleSelectedTitle;
                }
              }
            },
            items: items,
            icon: options.icon || null,
            hasDeselectAllButton: function () {
              return options.useDeselectAllButton && !ui.icon;
            },
            showDeselectAllButton: function () {
              return ui.hasDeselectAllButton() && ui.allSelected.length > 0;
            },
            theme: options.theme,
            select: function (value) {
              var allSelected, i, item, len;
              allSelected = [];
              for (i = 0, len = items.length; i < len; i++) {
                item = items[i];
                if (item.name === value.name) {
                  item.isSelected = !item.isSelected;
                }
                if (item.isSelected) {
                  item.visuallySelected = true;
                  allSelected.push(item);
                } else {
                  item.visuallySelected = false;
                }
              }
              ui.allSelected = allSelected;
              return onSelect(value, allSelected);
            },
            deselectAll: function (event) {
              var i, item, len;
              event.stopPropagation();
              for (i = 0, len = items.length; i < len; i++) {
                item = items[i];
                item.isSelected = false;
                item.visuallySelected = false;
              }
              ui.allSelected = [];
              return onSelect(null, []);
            },
            allSelected: [],
            visible: false,
            hide: function () {
              if (onClickOutside != null) {
                onClickOutside.stopListening();
              }
              return (ui.visible = false);
            },
            show: function () {
              onClickOutside = make(OnClickOutside, {
                ignoreElems: [".multi-dropdown"],
                onClickOutside: function () {
                  ui.hide();
                  return onClickOutside.stopListening();
                },
              });
              return (ui.visible = true);
            },
            toggle: function () {
              if (ui.visible) {
                return ui.hide();
              } else {
                return ui.show();
              }
            },
            isItemVisible: function (item) {
              if (!searchTerm) {
                return true;
              } else if (options.searchFn != null) {
                return options.searchFn(item, searchTerm);
              } else {
                return item.name.toLowerCase().includes(searchTerm);
              }
            },
            search: new Search(
              "Search",
              function (query) {
                if (query.length > 0) {
                  return (searchTerm = query.toLowerCase());
                } else {
                  return (searchTerm = null);
                }
              },
              {
                theme: "small",
              },
            ),
          }));
        };
      })(this);
    }

    return MultiSelectDropdownFactory;
  })();
}.call(this));
