"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidImageSrc = exports.ImageFrame = void 0;
var lodash_1 = require("lodash");
var URL_1 = require("@CommonFrontendBackend/Utils/URL");
var Image_1 = require("../Models/Image");
Object.defineProperty(exports, "ImageFrame", { enumerable: true, get: function () { return Image_1.ImageFrame; } });
var isValidImageSrc = function (src) {
    return (0, lodash_1.isString)(src) && (0, URL_1.isURL)(src);
};
exports.isValidImageSrc = isValidImageSrc;
