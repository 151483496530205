"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokenWidget = void 0;
var Common_1 = require("ContentTokens/Models/Common");
var Text_1 = require("./Text");
var tokenWidget = {
    createToken: function () {
        throw new Error("Should not be persisted");
    },
    createSlateJson: function () {
        return {
            type: Common_1.InlineType.TokenWidget,
            children: [Text_1.text.createSlateJson()],
            isVoid: true,
        };
    },
};
exports.tokenWidget = tokenWidget;
