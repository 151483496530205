"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationState = exports.TokenResolutionErrorCode = exports.toTemplateCollectionDisplayName = exports.TemplateCollection = exports.ExpandOption = exports.TemplatesWith = exports.SortField = exports.SortDirection = exports.TEMPLATES_FIRST_PAGE = exports.TemplateAccessControlStatus = void 0;
var TemplateAccessControlStatus;
(function (TemplateAccessControlStatus) {
    TemplateAccessControlStatus["Allowed"] = "Allowed";
    TemplateAccessControlStatus["NotAllowed"] = "NotAllowed";
})(TemplateAccessControlStatus || (exports.TemplateAccessControlStatus = TemplateAccessControlStatus = {}));
exports.TEMPLATES_FIRST_PAGE = 1;
var SortDirection;
(function (SortDirection) {
    SortDirection[SortDirection["Ascending"] = 1] = "Ascending";
    SortDirection[SortDirection["Descending"] = -1] = "Descending";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
var SortField;
(function (SortField) {
    SortField["Name"] = "name";
    SortField["DateCreated"] = "dateCreated";
    SortField["LastEdited"] = "lastEditedAt";
})(SortField || (exports.SortField = SortField = {}));
var TemplatesWith;
(function (TemplatesWith) {
    TemplatesWith["AcceptBlock"] = "acceptBlock";
    TemplatesWith["QuoteBlock"] = "quoteBlock";
})(TemplatesWith || (exports.TemplatesWith = TemplatesWith = {}));
var ExpandOption;
(function (ExpandOption) {
    ExpandOption["Automation"] = "automation";
})(ExpandOption || (exports.ExpandOption = ExpandOption = {}));
var TemplateCollection;
(function (TemplateCollection) {
    TemplateCollection["All"] = "all";
    TemplateCollection["Deleted"] = "deleted";
    TemplateCollection["Archived"] = "archived";
})(TemplateCollection || (exports.TemplateCollection = TemplateCollection = {}));
var toTemplateCollectionDisplayName = function (collection) {
    switch (collection) {
        case TemplateCollection.All:
            return "Templates";
        case TemplateCollection.Archived:
            return "Archived";
        case TemplateCollection.Deleted:
            return "Deleted";
        default:
            throw new Error("Unhandled TemplateCollection: ".concat(collection));
    }
};
exports.toTemplateCollectionDisplayName = toTemplateCollectionDisplayName;
var TokenResolutionErrorCode;
(function (TokenResolutionErrorCode) {
    TokenResolutionErrorCode["TOKEN_NOT_FOUND"] = "TOKEN_NOT_FOUND";
    TokenResolutionErrorCode["TOKEN_DEPRECATED"] = "TOKEN_DEPRECATED";
    TokenResolutionErrorCode["INTEGRATION_NOT_FOUND"] = "INTEGRATION_NOT_FOUND";
    TokenResolutionErrorCode["TOKEN_VALUE_NOT_IN_DOWNSTREAM_RESPONSE"] = "TOKEN_VALUE_NOT_IN_DOWNSTREAM_RESPONSE";
    TokenResolutionErrorCode["USER_AUTH_DATA_NOT_FOUND"] = "USER_AUTH_DATA_NOT_FOUND";
    TokenResolutionErrorCode["OBJECT_NOT_FOUND"] = "OBJECT_NOT_FOUND";
})(TokenResolutionErrorCode || (exports.TokenResolutionErrorCode = TokenResolutionErrorCode = {}));
var AutomationState;
(function (AutomationState) {
    AutomationState["Assignable"] = "assignable";
    AutomationState["Assigned"] = "assigned";
    AutomationState["Unassignable"] = "unassignable";
})(AutomationState || (exports.AutomationState = AutomationState = {}));
