// Generated by CoffeeScript 1.12.7
(function () {
  var events;

  events = {
    space: {
      bubbles: true,
      cancelBubble: false,
      cancelable: true,
      charCode: 0,
      code: "Space",
      keyCategory: 2,
      keyCode: 32,
      keyType: "typing",
      type: "keydown",
      which: 32,
    },
    backspace: {
      bubbles: true,
      cancelBubble: false,
      cancelable: true,
      charCode: 0,
      code: "Backspace",
      keyCategory: 1,
      keyCode: 8,
      keyType: "backspace",
      type: "keydown",
      which: 8,
    },
    delete: {
      bubbles: true,
      cancelBubble: false,
      cancelable: true,
      charCode: 0,
      code: "Delete",
      keyCategory: 1,
      keyCode: 46,
      keyType: "delete",
      type: "keydown",
      which: 46,
    },
    enter: {
      bubbles: true,
      cancelBubble: false,
      cancelable: true,
      charCode: 0,
      code: "Enter",
      keyCategory: 3,
      keyCode: 13,
      keyType: "typing",
      type: "keydown",
      which: 13,
    },
    up: {
      bubbles: true,
      cancelBubble: false,
      cancelable: true,
      charCode: 0,
      code: "ArrowUp",
      keyCategory: 4,
      keyCode: 38,
      keyType: "typing",
      type: "keydown",
      which: 38,
    },
  };

  module.exports.trigger = function (keyType, point) {
    var event;
    event = _.cloneDeep(events[keyType]);
    event.point = point;
    event.view = point.node.ownerDocument.defaultView;
    event.preventDefault = function () {};
    return event;
  };
}.call(this));
