"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultRgbaColor = void 0;
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
exports.defaultRgbaColor = {
    opacity: 0.25,
    abs: (0, ColorUtils_1.replaceOpacity)(DefaultColors_1.DEFAULT_MIDNIGHT.value, 0.25),
    themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
};
