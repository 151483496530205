"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokensTelemetry = void 0;
var TelemetryTypes_1 = require("@CommonFrontendBackend/Tokens/TelemetryTypes");
var TokensTelemetry = (function () {
    function TokensTelemetry(telemetry) {
        this.telemetry = telemetry;
    }
    TokensTelemetry.prototype.tokenCreated = function () {
        this.telemetry.track(TelemetryTypes_1.TokensEvents.TokenCreated);
    };
    TokensTelemetry.prototype.tokenDeleted = function () {
        this.telemetry.track(TelemetryTypes_1.TokensEvents.TokenDeleted, {}, { blockIntercom: true });
    };
    return TokensTelemetry;
}());
exports.TokensTelemetry = TokensTelemetry;
