"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAutoLinkExpiryChanges = exports.expiryDaysOnPublicPage = exports.daysUntilLinkExpiry = exports.calcExpiryDate = void 0;
var luxon_1 = require("luxon");
var lodash_1 = require("lodash");
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var DAYS_SHOW_EXPIRY_TIME = 7;
function calcExpiryDate(startDate, expireAfterDays) {
    if (expireAfterDays == undefined || startDate == undefined) {
        return null;
    }
    if (expireAfterDays < 0)
        throw new Error("Oops, expireAfterDays can't be negative!");
    var expiryDurationMs = luxon_1.Duration.fromObject({ days: expireAfterDays }).as("milliseconds");
    return luxon_1.DateTime.fromJSDate(new Date(startDate)).plus(expiryDurationMs).toJSDate();
}
exports.calcExpiryDate = calcExpiryDate;
function daysUntilLinkExpiry(startDate, expireAfterDays) {
    if (!startDate) {
        return expireAfterDays;
    }
    else {
        var expiryDate = calcExpiryDate(startDate, expireAfterDays);
        var timeUntilExpiry = expiryDate.getTime() - Date.now();
        return Math.ceil(luxon_1.Duration.fromMillis(timeUntilExpiry).as("days"));
    }
}
exports.daysUntilLinkExpiry = daysUntilLinkExpiry;
function expiryDaysOnPublicPage(autoLinkExpirySetting) {
    if ((0, lodash_1.isEmpty)(autoLinkExpirySetting))
        return -1;
    if (!autoLinkExpirySetting.enabled)
        return -1;
    var startDate = autoLinkExpirySetting.startDate, expireAfterDays = autoLinkExpirySetting.expireAfterDays;
    var remaingDays = daysUntilLinkExpiry(startDate, expireAfterDays);
    if (remaingDays > DAYS_SHOW_EXPIRY_TIME)
        return -1;
    return remaingDays;
}
exports.expiryDaysOnPublicPage = expiryDaysOnPublicPage;
function getAutoLinkExpiryChanges(oldStatus, newStatus, autoLinkExpiry) {
    if ((0, lodash_1.isEmpty)(autoLinkExpiry))
        return undefined;
    if (newStatus === oldStatus)
        return undefined;
    if (newStatus !== Types_1.ProjectStatus.Live && oldStatus !== Types_1.ProjectStatus.Live)
        return undefined;
    if (!autoLinkExpiry.enabled)
        return undefined;
    var startDate = autoLinkExpiry.startDate, expireAfterDays = autoLinkExpiry.expireAfterDays;
    if (newStatus === Types_1.ProjectStatus.Live) {
        var startDate_1 = new Date().toString();
        return {
            startDate: startDate_1,
            expiresOn: calcExpiryDate(startDate_1, expireAfterDays).toString(),
        };
    }
    if (oldStatus === Types_1.ProjectStatus.Live && newStatus === Types_1.ProjectStatus.Draft) {
        return {
            startDate: null,
            expiresOn: null,
            expireAfterDays: daysUntilLinkExpiry(startDate, expireAfterDays),
        };
    }
    return undefined;
}
exports.getAutoLinkExpiryChanges = getAutoLinkExpiryChanges;
