"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokensEvents = void 0;
var TokensEvents;
(function (TokensEvents) {
    TokensEvents["TokensViewed"] = "Tokens Viewed";
    TokensEvents["TokenCreated"] = "Token Created";
    TokensEvents["TokenDeleted"] = "Token Deleted";
    TokensEvents["TokenFormatted"] = "Token Formatted";
    TokensEvents["TokenSwapViewed"] = "Token Swap Viewed";
    TokensEvents["TokenSwapSubmitted"] = "Token Swap Submitted";
    TokensEvents["TokensSearched"] = "Tokens Searched";
})(TokensEvents || (exports.TokensEvents = TokensEvents = {}));
