"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_TAG_LENGTH = exports.SpecialProjectTag = void 0;
var SpecialProjectTag;
(function (SpecialProjectTag) {
    SpecialProjectTag["Archived"] = "archived";
    SpecialProjectTag["Starred"] = "starred";
    SpecialProjectTag["Template"] = "templateqwilr";
})(SpecialProjectTag || (exports.SpecialProjectTag = SpecialProjectTag = {}));
exports.MAX_TAG_LENGTH = 30;
