"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateNiceScale = void 0;
function calculateNiceScale(minTicks, maxTicks, minPoint, maxPoint) {
    var range = niceNum(maxPoint - minPoint, false);
    var tickSpacing = niceNum(range / (maxTicks - 1), true);
    var niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing;
    var idealTicks = Math.ceil(maxPoint / tickSpacing);
    var numTicks = Math.max(idealTicks, minTicks);
    var niceMax = numTicks * tickSpacing;
    return { tickSpacing: tickSpacing, niceMin: niceMin, niceMax: niceMax };
}
exports.calculateNiceScale = calculateNiceScale;
function niceNum(range, round) {
    var niceFraction;
    var exponent = Math.floor(Math.log10(range));
    var fraction = range / Math.pow(10, exponent);
    if (round) {
        if (fraction < 1.5)
            niceFraction = 1;
        else if (fraction < 3)
            niceFraction = 2;
        else if (fraction < 7)
            niceFraction = 5;
        else
            niceFraction = 10;
    }
    else {
        if (fraction <= 1)
            niceFraction = 1;
        else if (fraction <= 2)
            niceFraction = 2;
        else if (fraction <= 5)
            niceFraction = 5;
        else
            niceFraction = 10;
    }
    return niceFraction * Math.pow(10, exponent);
}
