"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoteDataStatus = void 0;
var RemoteDataStatus;
(function (RemoteDataStatus) {
    RemoteDataStatus["NotRequested"] = "notRequested";
    RemoteDataStatus["Loading"] = "loading";
    RemoteDataStatus["Success"] = "success";
    RemoteDataStatus["Error"] = "error";
})(RemoteDataStatus || (exports.RemoteDataStatus = RemoteDataStatus = {}));
