"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptMultiEsign = exports.AcceptEsign = exports.AcceptClickTo = void 0;
var AcceptPresets_1 = require("./AcceptPresets");
var base = {
    name: "",
    type: "Accept",
    cssBlockName: "accept-module",
    settings: AcceptPresets_1.baseSettings,
    contentAreas: {
        main: {
            content: "<h2 placeholder=\"true\"><br></h2>\n<p placeholder=\"true\"><br></p>",
            widgets: {},
        },
    },
};
exports.default = base;
exports.AcceptClickTo = base;
exports.AcceptEsign = __assign(__assign({}, base), { settings: AcceptPresets_1.esignSettings });
exports.AcceptMultiEsign = __assign(__assign({}, base), { settings: AcceptPresets_1.multiEsignSettings });
