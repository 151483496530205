"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockTypeForTelemetry = exports.getContentTypeForTelemetry = void 0;
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var getContentTypeForTelemetry = function (blockSubType) {
    switch (blockSubType) {
        case "sideBySide":
            return "columns";
        case "googleMaps":
            return "maps";
        case "roiCalculator":
            return "roi";
        case "iframe":
            return "embed";
        case "quoteSummary":
            return "quotesummary";
        default:
            return blockSubType;
    }
};
exports.getContentTypeForTelemetry = getContentTypeForTelemetry;
var getBlockTypeForTelemetry = function (blockType) {
    switch (blockType) {
        case BlockTypes_1.BlockType.Source:
            return "embed";
        default:
            return blockType;
    }
};
exports.getBlockTypeForTelemetry = getBlockTypeForTelemetry;
