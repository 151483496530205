"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractChannelIds = exports.buildChannelName = void 0;
var channelNameRegx = /^private-project-(.+)$/;
var objectIdRegEx = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
var buildChannelName = function (projectId, blockId) {
    if (blockId) {
        return "private-project-".concat(projectId, "-").concat(blockId);
    }
    return "private-project-".concat(projectId);
};
exports.buildChannelName = buildChannelName;
var isValidObjectId = function (idToValidate) {
    return objectIdRegEx.test(idToValidate);
};
var extractChannelIds = function (channelName) {
    var match = channelName.match(channelNameRegx);
    if (!match) {
        return null;
    }
    var idsString = match[1];
    var channelIdSeparator = "-";
    var ids = idsString.split(channelIdSeparator);
    var projectId = ids[0];
    var blockId = ids[1];
    if (!isValidObjectId(projectId) || (!!blockId && !isValidObjectId(blockId))) {
        return null;
    }
    return {
        projectId: projectId,
        blockId: blockId,
    };
};
exports.extractChannelIds = extractChannelIds;
