"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultBrandFetchDetails = exports.MAX_BRAND_FETCH_COLORS_COUNT = exports.MAX_BRAND_FETCH_LOGOS_COUNT = exports.LOGO_NAME_PREFIX = exports.BrandFetchType = exports.BrandFetchState = void 0;
var BrandFetchState;
(function (BrandFetchState) {
    BrandFetchState["Empty"] = "empty";
    BrandFetchState["AutoFetched"] = "autoFetched";
    BrandFetchState["ManuallyFetched"] = "manuallyFetched";
    BrandFetchState["AutoFetchFailed"] = "autoFetchFailed";
    BrandFetchState["ManualFetchFailed"] = "manualFetchFailed";
})(BrandFetchState || (exports.BrandFetchState = BrandFetchState = {}));
var BrandFetchType;
(function (BrandFetchType) {
    BrandFetchType["Auto"] = "auto";
    BrandFetchType["Manual"] = "manual";
})(BrandFetchType || (exports.BrandFetchType = BrandFetchType = {}));
exports.LOGO_NAME_PREFIX = "Logo";
exports.MAX_BRAND_FETCH_LOGOS_COUNT = 5;
exports.MAX_BRAND_FETCH_COLORS_COUNT = 4;
exports.defaultBrandFetchDetails = {
    state: BrandFetchState.Empty,
    name: "",
    domain: "",
    logos: [],
    colors: [],
};
