"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalServerError = void 0;
var ErrorCodes_1 = require("@CommonFrontendBackend/ErrorCodes/ErrorCodes");
var InternalServerError = (function (_super) {
    __extends(InternalServerError, _super);
    function InternalServerError(message, reportType, additionalInfo) {
        if (message === void 0) { message = "Internal Server Error"; }
        if (reportType === void 0) { reportType = ErrorCodes_1.ReportType.Error; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        var _this = _super.call(this, message) || this;
        _this.message = message;
        _this.reportType = reportType;
        _this.additionalInfo = additionalInfo;
        _this.name = "InternalServerError";
        _this.statusCode = 500;
        _this.userPresentable = true;
        Object.setPrototypeOf(_this, InternalServerError.prototype);
        return _this;
    }
    return InternalServerError;
}(Error));
exports.InternalServerError = InternalServerError;
